
import { SectionText, SectionTitle } from '@/components/ui/text';

import smokeImg from '@/assets/images/smoke.webp'
import smokeTopImg from '@/assets/images/smoke-top.webp'
import smokeBottomImg from '@/assets/images/smoke-bottom.webp'
import rocketImg from '@/assets/images/rocket.webp'
import rocketImgMobile from '@/assets/images/heroes/mobile/rocket.webp'
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { cn } from '@/lib/utils';
import { useSections } from '@/components/section-provider';
import { forwardRef, useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import thoraxVideo from '@/assets/animations/thorax.webm'
import { VideoAnimation } from '@/components/animations/video';
import { SectionProps } from '@/registry/home-sections';

export const LizardLaunchpad = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {

    const { isInView, id } = props;
    
    return (
        <>
        <motion.section id={id} ref={ref} key="lizlaunchpad" className={cn('flex h-full w-full', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>         
            <div className='relative px-4 w-full h-2/3 md:h-full lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-start md:items-center pt-2 md:pt-0'>
                <div className='flex flex-col col-span-full gap-6 lg:gap-8 w-full px-2 lg:px-0 text-left items-start'>
                    <div className='flex flex-col gap-6 lg:gap-8 justify-center'>
                        <SectionTitle isInView={isInView}>Lizard Launchpad</SectionTitle>
                        <SectionText isInView={isInView} className='lg:max-w-xl text-base font-figtree !leading-6'>
                            Are you a degen that dreams of being a VC? Blast off with the Lizard Launchpad and get access to the best early stage and public gaming token deals.
                        </SectionText>
                    </div>
                    <motion.a 
                        target='_blank'
                        href="https://launchpad.lizlabs.io"
                        className={cn(buttonVariants(), 'h-14 px-6 py-7 pt-[26px] font-normal lg:font-semibold font-nekstsemibold text-base lg:text-xl !leading-5 lg:!leading-6')}
                        {...slideProps("up", isInView, true)}
                    >
                        Access Token Deals
                    </motion.a>
                </div>
            </div>
        </motion.section>
        <motion.img 
            src={smokeBottomImg} 
            className='fixed bottom-0 w-full z-0 flex md:hidden' 
            {...slideProps("left", isInView)}
        />
        <BrowserView>
            <motion.img 
                src={smokeTopImg} 
                className='fixed inset-0 w-full z-0 flex md:hidden' 
                {...slideProps("left", isInView)}
            />
            <motion.img 
                src={smokeImg} 
                className='fixed inset-0 h-full w-full z-0 hidden md:flex' 
                {...slideProps("left", isInView)}
            />
            
            {/* <motion.img
                src={rocketImg}
                className='fixed right-0 bottom-0 lg:bottom-[unset] h-1/2 md:top-[10%] md:h-[80%]'
                {...slideProps("right",isInView)}
            /> */}
            <VideoAnimation
                sectionIndex={6}
                isInView={isInView}
                videoId='thorax'
                className='fixed right-0 bottom-0 lg:bottom-[unset] md:top-[18%] md:h-[70%]'
                {...slideProps("right",isInView)}
            />
        </BrowserView>
        <MobileView>
            <motion.img
                src={rocketImgMobile}
                className='fixed bottom-2 right-0 mx-auto z-0 w-3/4'
                {...slideProps("right", isInView)}
            />
        </MobileView>
        </>
    );
});