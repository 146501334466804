import {
    Dialog,
    DialogContent,
    DialogOverlay
} from "@/components/ui/dialog"
import { Dispatch, SetStateAction } from "react"
import modalBg from "@/assets/images/modal-bg.webp";
import logo from "@/assets/images/lizard-labs-logo.svg";
import { SocialIcons } from "@/components/footer";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";

export const SocialsModal = ({ open, onOpenChange }: { open: boolean, onOpenChange: Dispatch<SetStateAction<boolean>> }) => {

    return <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="max-w-[746px] px-5 gap-y-0 border-0">
            <div className="relative border-4 border-[#6D40EE] shadow-modal bg-modal !rounded-[20px]">
                <Button 
                    className="absolute -top-[23px] -right-[22px] bg-modal border-4 border-[#6D40EE] rounded-full !w-[45px] !h-[45px] z-10 !p-0"
                    onClick={() => onOpenChange(false)}
                >
                    <X className="w-6 h-6" color="white" />
                </Button>
                <div className="relative h-[134px] lg:h-[194px] w-full !rounded-[20px]">
                    <div 
                        className="h-full w-full bg-no-repeat bg-cover bg-center !rounded-t-[20px]"
                        style={{ backgroundImage: `url(${modalBg})` }}
                    />
                    <div className="absolute inset-0 h-full w-full bg-black bg-opacity-30 !rounded-t-[20px]" />
                </div>
                <div className="relative flex flex-col items-center justify-start w-full pb-10 lg:pb-[60px]">
                    <div className="absolute mx-auto -top-[53px] rounded-full h-[90px] w-[90px] lg:h-[121px] lg:w-[121px] bg-cyan-hex p-[5px]" /> 
                    <img src={logo} className="absolute mx-auto -top-[42px] lg:-top-[53px] ml-[11px] -mt-[8px] lg:ml-[14px] lg:mt-[4px] w-[95px] h-[84px] lg:w-[129px] lg:h-[113px]" />
                    <div className="flex flex-col justify-center items-center text-center w-full gap-y-3 lg:gap-y-[28px] mt-20 px-9 lg:px-20">
                        <h3 className="text-white font-nekstbold text-xl leading-6 lg:text-[32px] font-bold lg:leading-[36px]">Lizard Labs</h3>
                        <p className="text-white leading-5 text-sm lg:text-xl lg:leading-6 font-figtree font-normal">
                            Embrace your inner Lizard and join us as we pioneer play with top Web3 studios, shaping the future of Web3 Gaming
                        </p>
                        <p className="text-white leading-5 text-sm lg:text-xl lg:leading-6 font-figtree">Are you ready to Scale Up Your Game™?</p>
                    </div>
                    <SocialIcons className="mt-[44px] gap-x-5" iconClassName="bg-blue rounded-[14px] border-blue shadow-blue-social h-12 w-12 hover:bg-blue/90 hover:scale-100" />
                </div>
            </div>
        </DialogContent>
    </Dialog>
}