import merchImg from '@/assets/images/merch.webp';
import merchImgMobile from '@/assets/images/merch-mobile.png';
import { CollectionCard } from '@/components/sections/ethlizards/our-collections';
import { SectionTitle } from '@/components/ui/text';
import { useInView, motion } from 'framer-motion';
import { useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';

const description = "Discover our latest collection of exclusive merchandise, designed with you in mind! Shop now to elevate your style and showcase your passion. Don't miss out—these items are limited edition and won't last long!";

const MerchStore = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="merch"
            ref={ref}
            className="relative flex flex-col justify-start items-center mb-28 gap-y-6 lg:gap-y-20 px-6 md:px-20 py-8 md:py-0 text-center"
        >
            <SectionTitle divRef={ref} isInView={isInView} className="lg:!text-5xl">But Wait, There's More</SectionTitle>
            <div className='relative flex w-full max-w-6xl'>
                <BrowserView className='w-full'>
                    <CollectionCard
                        benefit={{
                            boxClass: "",
                            title: "Ethlizards Merch",
                            image: merchImg,
                            mobileImage: null,
                            description: description,
                            button: {
                                text: "Buy Limited Edition Merch",
                                link: "https://merch.lizlabs.io/"
                            }
                        }}
                    />
                </BrowserView>
                <MobileView className='rounded-[20px]' style={{ backgroundImage: 'linear-gradient(180deg, rgba(31, 29, 64, 0.30) 0%, rgba(31, 29, 64, 0.83) 40%, #1F1D40 100%)'}}>
                    <div className='relative flex flex-col gap-y-3 rounded-[20px] p-6 pb-0 text-left overflow-hidden bg-black/50'>
                        <h5 className='text-white text-xl font-nekstbold !leading-6'>Ethlizards Merch</h5>
                        <p className='text-white font-figtree'>{description}</p>
                        <div className='relative w-full flex flex-col justify-center items-center'>
                            <motion.img 
                                initial={{ opacity: 0, y: -150 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                                src={merchImgMobile} 
                                alt="Ethlizards Mission" 
                                className='w-full h-auto mt-2 relative' 
                            />
                            <a
                                target="_blank"
                                href="https://merch.lizlabs.io/"
                                className={cn(buttonVariants({ variant: 'cta' }), "absolute mx-auto bottom-5 h-10 text-base px-4")}
                            >Buy Limited Edition Merch</a>
                        </div>
                    </div>
                </MobileView>
            </div>
        </section>
    );
};

export default MerchStore;