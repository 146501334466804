import { SectionTitle } from '@/components/ui/text';
import genesisImg from '@/assets/images/collections/genesis.webp';
import ventureImg from '@/assets/images/collections/venture.webp';
import larpImg from '@/assets/images/collections/larp.webp';
import specialImg from '@/assets/images/collections/special.webp';
import colMobImg1 from '@/assets/images/collections/mobile/mob-coll-1.webp';
import colMobImg2 from '@/assets/images/collections/mobile/mob-coll-2.webp';
import colMobImg3 from '@/assets/images/collections/mobile/mob-coll-3.webp';
import colMobImg4 from '@/assets/images/collections/mobile/mob-coll-4.webp';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useRef } from 'react';
import { useInView, motion } from 'framer-motion';
import { slideProps, transition } from '@/components/animations/utils';
import { BrowserView, MobileView } from 'react-device-detect';
import { Carousel, CarouselContent, CarouselDots, CarouselItem } from '@/components/ui/carousel';

interface BenefitType {
    image: string;
    mobileImage: JSX.Element | null;
    boxClass: string;
    title: string;
    description: string;
    button: {
        text: string;
        link: string;
    }
}

const benefits: BenefitType[] = [
    {
        image: genesisImg,
        mobileImage: <img src={colMobImg1} className="absolute -top-24 -right-24 scale-[1.75] opacity-30 lg:opacity-50" />,
        boxClass: "bg-collection-yellow border-opacity-25 bg-opacity-50",
        title: "Genesis Lizards",
        button: {
            text: "Buy Genesis Lizards",
            link: "https://blur.io/collection/ethlizards-genesis"
        },
        description: "The best, the bees knees, the VIP NFT - The Ethlizards OG collection. 100 NFTs that started it all. Holders get the best whitelists, token deal allocations, token and NFT airdrops, 2x revenue rewards and more."
    },
    {
        image: ventureImg,
        mobileImage: <img src={colMobImg2} className="absolute -top-24 -right-24 scale-[1.75] opacity-30 lg:opacity-50" />,
        boxClass: "bg-benefit-blue border-opacity-25 bg-opacity-50",
        title: "Venture Lizards",
        button: {
            text: "Buy Venture Lizards",
            link: "https://blur.io/collection/ethlizards"
        },
        description: "The second generation of 5050 unique NFTs that 'scaled' the project. These legendary lizards can be spotted everywhere that there are good games or profits to be made. Holders get the best whitelists, token deal allocations, token and NFT airdrops, revenue rewards and more."
    },
    {
        image: larpImg,
        mobileImage: <img src={colMobImg3} className="absolute -top-24 -right-24 scale-[1.75] opacity-80 lg:opacity-50" />,
        boxClass: "bg-benefit-purple border-opacity-25 bg-opacity-50",
        title: "LARP Suit",
        button: {
            text: "Buy LARP Suit",
            link: "https://blur.io/eth/collection/larp-suits"
        },
        description: "Our free mint collection that opens up the project to more frens of the lizards. 3,333 suits to let you LARP as a lizard, access our private community and participate in our exclusive Launchpad deals."
    },
    {
        image: specialImg,
        mobileImage: <img src={colMobImg4} className="absolute -top-24 -right-24 scale-[1.75] opacity-50 lg:opacity-50" />,
        boxClass: "bg-benefit-purple border-opacity-25 bg-opacity-50",
        title: "Special Edition",
        button: {
            text: "Buy Special Editions",
            link: "https://opensea.io/collection/ethlizards-special-edition"
        },
        description: "Custom collection used to capture special moments in time and artist collaborations to make unique lizards. Holders get access our private community and can participate in our exclusive Launchpad deals."
    }
]

export const CollectionCard = ({ benefit }: { benefit: BenefitType }) => {  

    const ref = useRef(null);
    const isInView = useInView(ref);

    return <motion.article 
            ref={ref} 
            className={cn("relative flex justify-start items-center text-left rounded-[20px] px-10 py-10 min-h-[300px] w-full bg-origin-border", benefit.boxClass)}
            {...slideProps("up", isInView)}
            initial={{ y: 100 }}
            variants={{
                show: {
                    y: 0
                },
                hide: {
                    y: -100
                }
            }}
            animate={isInView ? "show" : "hide"}
            transition={transition}
        >
        <div className='absolute inset-0 w-full h-full rounded-[10px] overflow-hidden'>
            <div 
                style={{ backgroundImage: `url(${benefit.image})`, backgroundSize: 'auto 100%', content: ' ' }} 
                className='block absolute w-full bg-no-repeat h-full z-0 bg-left-bottom lg:bg-right-top opacity-30 lg:opacity-50'
            />
        </div>
        <div className='relative flex flex-col gap-y-6 max-w-[400px]'>
            <span className='font-nekstbold text-4xl text-foreground !leading-10'>{benefit.title}</span>
            <span className='text-base font-figtree text-foreground !leading-5'>{benefit.description}</span>
        </div>
        <a href={benefit.button.link} className={cn(buttonVariants({ variant: "cta" }), "!absolute right-0 -bottom-6 !h-12 !px-6 !py-2.5")} target='_blank'>
            {benefit.button.text}
        </a>
    </motion.article>
}

export const CollectionCardMobile = ({ benefit }: { benefit: BenefitType }) => {  

    const ref = useRef(null);
    const isInView = useInView(ref);

    return <article 
            ref={ref} 
            className={cn("relative flex flex-col gap-y-3 justify-end items-center text-left rounded-[20px] px-6 py-4 w-full bg-origin-border h-[290px]", benefit.boxClass, "shadow-none")}
        >
        <div className='absolute inset-0 w-full h-full rounded-[10px] overflow-hidden'>
            {benefit.mobileImage}
        </div>
        <div className='relative flex flex-col gap-y-3'>
            <span className='font-nekstbold text-xl text-white !leading-6'>{benefit.title}</span>
            <span className='text-[14px] font-figtree text-white !leading-5'>{benefit.description}</span>
        </div>
        <a href={benefit.button.link} className={cn(buttonVariants({ variant: "cta" }), "w-auto !h-12 !px-6 !py-2.5")} target='_blank'>
            {benefit.button.text}
        </a>
    </article>
}

const OurCollections = () => {
    return (
        <section 
            id="collections"
            className="relative flex flex-col justify-start items-center mb-28 gap-y-8 px-6 md:px-20 text-center"
        >
            <SectionTitle isInView={true} className="relative text-2xl lg:!text-5xl">Choose Your Collection</SectionTitle>
            <BrowserView className='relative flex flex-col gap-y-14 w-full max-w-6xl lg:py-20'>
                {benefits.map((bnft) => (
                    <CollectionCard key={`collection_${bnft.title}`} benefit={bnft} />
                ))}
            </BrowserView>
            <MobileView className='w-full'>
                <Carousel>
                    <CarouselContent className='flex gap-x-4'>
                        {benefits.map((bnft) => (
                            <CarouselItem key={bnft.title} className='w-full overflow-hidden'>
                                <CollectionCardMobile key={`collection_${bnft.title}`} benefit={bnft} />
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                    <CarouselDots />
                </Carousel>
            </MobileView>
        </section>
    );
};

export default OurCollections;