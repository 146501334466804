import nftArtImage from '@/assets/images/heroes/5.webp';
import nftArtImageMobile from '@/assets/images/heroes/mobile/ethlizards-home.webp';
import { SectionText, SectionTitle } from '@/components/ui/text';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { isMobile } from 'react-device-detect';

const EthlizardsNFTs = () => {
    return (
        <section 
            id="home"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start lg:mb-28 gap-y-8 pt-20 lg:pt-8 md:pt-0 px-6 md:px-20 h-[calc(100vh-14rem)] md:h-[calc(100vh-8rem)] bg-right-top lg:bg-auto bg-no-repeat bg-auto-full"
            style={{ backgroundImage: `url(${isMobile ? nftArtImageMobile : nftArtImage})`, backgroundSize: 'contain'}}
        >
            <SectionTitle isInView={true} className='text-[32px] lg:text-[60px] !leading-9 lg:!leading-[64px]'>Ethlizards</SectionTitle>
            <div className='flex flex-col gap-y-3'>
                <SectionText isInView={true} className='text-foreground font-nekstbold text-xl lg:text-3xl !leading-5 lg:!leading-10'>
                    We're More than a Pretty Face
                </SectionText>
                <SectionText isInView={true} className='max-w-[300px] md:max-w-lg !leading-5'>
                    Forge the future of Web3 gaming and look cool doing it.
                </SectionText>
            </div>
            <a href="#collections" className={cn(buttonVariants({ variant: "cta" }), '!h-12 text-[18.5px]')}>
                Access our Collections
            </a>
            <div className="absolute left-0 bottom-0 h-20 bg-fade-out-purple w-full z-[1]" />
        </section>
    );
};

export default EthlizardsNFTs;