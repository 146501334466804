import lizcoinImage from '@/assets/images/heroes/7.webp';
import { SectionText, SectionTitle } from '@/components/ui/text';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { useEffect, useRef } from 'react';
import { VideoAnimation } from '@/components/animations/video';
import lizcoinVideo from '@/assets/animations/lizcoin.webm';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { useSite } from '@/site-provider';
import uniswapIcon from '@/assets/images/logos/uniswap.svg';
import { useLizcoinCTA } from '@/components/sections/lizcoin/cta/provider';

const LizcoinHero = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    const { setLoading } = useSite();
    const { setStage } = useLizcoinCTA();

    const openUniswap = () => {
        window.open("https://app.uniswap.org/explore/tokens/ethereum/0xAF4144cd943ed5362Fed2BaE6573184659CBe6FF", "_blank");
        setStage(1);
    }

    useEffect(() => {
        if (isMobile) {
            setLoading(false);
        }
    }, [isMobile])

    return (
        <section 
            id="lizcoin-hero"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:items-center md:justify-between md:flex-row gap-y-8 pt-20 md:pt-0 px-6 lg:min-h-[100vh]"
        >
            <div className='bg-eclipse absolute left-0 -top-[25%] h-[150%] w-[120%] rounded-full z-0' />
            <div className='relative flex flex-col justify-center items-start gap-y-8 z-[1] pt-8 lg:pt-0'>
                <div className='flex flex-col gap-y-4 lg:gap-y-[18px]'>
                    <SectionTitle ish1 isInView={true} className='text-[32px] lg:!text-[72px] !leading-9 lg:!leading-[80px] text-yellow'>Your Key to <br/> Web3 Gaming</SectionTitle>
                    <SectionText isInView={true} className='text-foreground font-nekstbold text-xl lg:text-[32px] !leading-5 lg:!leading-9'>
                        Lizcoins are on DEX
                    </SectionText>
                    <div className='flex lg:pt-[26px]'>
                        <motion.button
                            onClick={openUniswap}
                            className={cn(buttonVariants({ variant: 'default' }), 'bg-cta border-[#F9EC31] border-[3px] text-[#1F1D40] font-figtree !font-bold shadow-cta px-6 lg:py-[26px] h-[40px] lg:h-[58px] gap-2 !text-base lg:!text-xl leading-7 hover:scale-[1.02] !rounded-[66px]')}
                            {...slideProps("up", true, true)}
                        >
                            <img src={uniswapIcon} className='block w-7 h-7' />
                            <span>Buy $LIZ on Uniswap</span>
                        </motion.button>
                    </div>
                </div>
            </div>
            <div ref={ref} className='flex justify-center md:justify-end z-[1]'>
                <BrowserView renderWithFragment>
                    <VideoAnimation
                        isInView={true}
                        src={lizcoinVideo}
                        autoPlay
                        className='max-w-[325px] md:max-w-[425px] xl:max-w-[700px] 2xl:max-w-[800px]'
                        onLoadedData={() => setLoading(false)}
                        {...slideProps("right", true)}
                    />
                </BrowserView>
                <MobileView className='flex flex-col w-full items-center'>
                    <motion.img
                        src={lizcoinImage}
                        className='max-w-[325px] md:max-w-[425px] xl:max-w-[700px]'
                        {...slideProps("right", true)}
                        alt="Lizcoin by Lizard Labs - Your Key to Web3 Gaming"
                    />
                    {/* <Button disabled className={cn(buttonVariants({ variant: "outline" }), '!py-6')}>
                        Exchange listing coming soon...
                    </Button> */}
                </MobileView>
            </div>
            <div className="absolute bottom-0 lg:h-32 bg-fade-in-purple w-full z-[1]" style={{ backgroundImage: 'linear-gradient(0deg, #000 0%, #000 60.5%, rgba(20, 20, 24, 0.00) 100%)', filter: 'blur(35px)'}} />
        </section>
    );
};

export default LizcoinHero;