import platformImg from '@/assets/images/lizard-platform.webp';
import platformImgMobile from '@/assets/images/heroes/mobile/mission.webp';
import { SectionText, SectionTitle } from '@/components/ui/text';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

const OurMission = () => {
    
    const ref = useRef(null);
    const descRef = useRef(null);
    const isInView = useInView(descRef);

    return (
        <section 
            id="mission"
            className="relative flex flex-col justify-start items-center h-100vh mb-28 py-8 md:py-0 text-center"
        >
            <div className='flex flex-col gap-y-6 lg:gap-y-8 px-6 md:px-20'>
            
                <SectionTitle divRef={ref} isInView={true} className="lg:!text-5xl">Your Mission</SectionTitle>
                <SectionText divRef={descRef} isInView={true} className='md:max-w-[648px] lg:text-xl !leading-6'>
                    Forge the future of Web3 gaming by owning one of the original Ethlizards NFTs. 
                    Build and invest in immersive and accessible experiences for gamers while earning real rewards. Make Web3 gaming inevitable.
                </SectionText>
                
            </div>
            <div className='relative lg:py-12'>
                <MobileView>
                    <motion.img 
                        initial={{ opacity: 0, y: -150 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        src={platformImgMobile} 
                        alt="Ethlizards Mission" 
                        className='w-full h-auto' 
                    />
                    <div className="absolute left-0 -bottom-10 h-20 bg-fade-out-purple w-full z-[1]" />
                </MobileView>
                <BrowserView>
                    <motion.img 
                        initial={{ opacity: 0, y: -150 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        src={platformImg} 
                        alt="Ethlizards Mission" 
                        className='w-full h-auto' 
                    />
                </BrowserView>
            </div>
        </section>
    );
};

export default OurMission;