import * as React from "react";
import { motion } from "framer-motion";
import { PageSection } from "@/registry/home-sections";
import { useSections } from "@/components/section-provider";
import { cn } from "@/lib/utils";
import dot from '@/assets/images/dot.png';

export const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const MenuItem = ({ i, section, sectionPress }: { i: number, section: PageSection, sectionPress: (x: number) => void }) => {

  const { section: activeSection } = useSections();

  return (
    <motion.li
      variants={itemVariants}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 1 }}
      className={cn(
        "relative flex items-center font-nekstsemibold text-xl lg:text-2xl !leading-6 lg:!leading-7 hover:cursor-pointer",
        activeSection === i ? 'text-cyan gap-x-4 lg:gap-x-5' : 'text-white pl-8 lg:pl-10'
      )}
      onClick={() => sectionPress(i)}
    >
      {activeSection === i && (
        <img src={dot} className="w-5 h-5" />
      )}
      {section.title}
    </motion.li>
  );
};
