import genesis from '@/assets/images/breakdown/genisis.png';
import chi from '@/assets/images/breakdown/chi.png';
import venture from '@/assets/images/breakdown/venture.png';
import larp from '@/assets/images/breakdown/larp.png';
import { SectionText, SectionTitle } from '@/components/ui/text';
import { LucideCheck } from 'lucide-react';
import { cn } from '@/lib/utils';
import { BrowserView, MobileView } from 'react-device-detect';
import LizardBreakdownMobile from '@/components/sections/ethlizards/lizard-breakdown';

interface BenefitType {
    title: string;
    values: any[];
}

interface BenefitTableType {
    image: React.ReactElement<HTMLImageElement>[];
    index: number;
    title: string;
    borderClass: string;
    bgClassName: string;
    dropShadow: string;
    url: string;
}

const benefits: BenefitType[] = [
    {
        title: "Ethlizards Membership",
        values: [
            <span className='text-[#FDB10D] text-2xl' style={{ textShadow: "0px 0px 12px #FDB10D" }}>LVL 3</span>,
            <span className='text-[#5E78D8] text-xl' style={{ textShadow: "0px 0px 12px #5E78D8" }}>LVL 2</span>, 
            <span className='text-white text-base'>LVL 1</span> 
        ]
    },
    {
        title: "Portfolio Revenue Rewards",
        values: ["2x", "1x", ""]
    },
    {
        title: "Exclusive Discord Access",
        values: ["LVL 3", "LVL 2", "LVL 1"]
    },
    {
        title: "Exclusive Whitelist Raffles",
        values: ["LVL 2", "LVL 2", "LVL 1"]
    },
    {
        title: "Lizard Launchpad Access",
        values: ["0% Fees", "0% Fees", "4% Fees"]
    },
    {
        title: "Lizard Launchpad Allocations",
        values: ["4x", "2x", "1x"]
    },
    {
        title: "Elemental Lizard Airdrops",
        values: [<LucideCheck />, <LucideCheck />, null]
    },
    {
        title: "Lizcoin $LIZ Airdrops",
        values: [<LucideCheck />, <LucideCheck />, null]
    },
    {
        title: "Governance Voting",
        values: [<LucideCheck />, <LucideCheck />, null]
    }
];

const tables: BenefitTableType[] = [
    {
        image: [<img key="vgenesis_nft_1" src={genesis} className='absolute bottom-0 max-h-24 left-24 lg:left-6' />],
        index: 2,
        title: "Ethlizards: Genesis Lizards",
        borderClass: "border-benefit-yellow",
        bgClassName: "bg-benefit-yellow",
        dropShadow: "drop-shadow(0px 4px 34px #FDB10D)",
        url: "https://blur.io/collection/ethlizards-genesis"
    },
    {
        image: [<img key="venture_nft_1" src={venture} className='absolute bottom-0 max-h-28 left-20 lg:left-6' />],
        index: 1,
        title: "Ethlizards: Venture Lizards",
        borderClass: "border-benefit-blue",
        bgClassName: "bg-benefit-blue",
        dropShadow: "drop-shadow(0px 4px 34px #5E78D8)",
        url: "https://blur.io/eth/collection/ethlizards"
    },
    {
        image: [
            <img key="larp_nft_1" src={larp} className='absolute bottom-0 left-10 lg:left-4 w-20 lg:w-20' />,
            <img key="larp_nft_2" src={chi} className='absolute bottom-0 right-8 lg:right-0 w-40 lg:w-[150px]' />
        ],
        index: 0,
        title: "Ethlizards LARP Suit & Special Edition NFTs",
        borderClass: "border-benefit-purple",
        bgClassName: "bg-benefit-purple",
        dropShadow: "",
        url: "https://blur.io/eth/collection/larp-suits"
    },
];

const LizardBreakdown = () => {
    return (
        <section 
            id="breakdown"
            className="relative flex flex-col gap-y-12 justify-start items-center mb-28 px-6 md:px-20 text-center"
        >
            <div className='flex flex-col gap-y-6'>
                <SectionTitle isInView={true} className="relative lg:!text-5xl">Ethlizards Breakdown</SectionTitle>
                <SectionText isInView={true}>Choose your champion</SectionText>
            </div>

            <BrowserView>
                <table className="border-separate border-spacing-x-5">
                    <thead>
                        <tr>
                            <th></th>
                            {tables.map((tbl) => (
                                <th 
                                    key={`tbl_head_${tbl.index}`}
                                    className='relative h-32'
                                >
                                    {tbl.image}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            <th className='text-left w-[362px]'>
                                <h5 className='text-foreground text-4xl font-nekstbold'>Benefits</h5>
                            </th>
                            {tables.map((tbl) => (
                                <th 
                                    key={`benefit_header_${tbl.index}`} 
                                    className={cn("relative min-h-16 px-4 py-3 w-[226px] rounded-t-[20px] border-opacity-50 border-x border-t overflow-hidden", tbl.borderClass)}
                                >
                                    <div className={cn("absolute inset-0 w-full h-full opacity-50", tbl.bgClassName)} />
                                    <span className="text-white text-base font-nekstbold">{tbl.title}</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {benefits.map((bnft, x) => (
                            <tr key={`benefit_row_${bnft.title}`} className={cn('h-[60px] justify-center items-center')}>
                                <td className={cn('text-left')}>
                                    <span className="text-white text-base font-nekstbold">{bnft.title}</span>
                                </td>
                                {bnft.values.map((val, y) => {
                                    const z = x + y;
                                    return <td 
                                        key={`benefit_row_${bnft.title}_col_${y}`} 
                                        style={{ zIndex: z }}
                                        className={cn(
                                            'relative text-center items-center justify-center text-white text-base font-nekstbold border-x border-opacity-50 overflow-hidden', 
                                            tables[y].borderClass,
                                            x === benefits.length - 1 ? "border-b rounded-b-[20px]" : "",
                                        )}
                                    >
                                        <div className='absolute w-full top-0 h-[1px] opacity-50 bg-benefit-blue' />
                                        <div className={cn("absolute inset-0 w-full h-full opacity-50", tables[y].bgClassName)} />
                                        <div className='flex justify-center items-center'>{val}</div>
                                    </td>
                                })}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot className='pt-4'>
                        <tr className='pt-4'>
                            <td></td>
                            {tables.map((tbl, x) => (
                                <td key={`tbl_btn_${tbl.index}`}>
                                    <a 
                                        target='_blank'
                                        href={tbl.url}
                                        className={cn(
                                            'flex items-center justify-center rounded-[40px] py-2.5 border-benefit-purple mt-4 border hover:scale-[1.02] transition-all duration-200', 
                                            tbl.bgClassName, 
                                            tbl.borderClass,
                                            x === 0 ? "shadow-buy-now" : ""
                                        )}
                                    >
                                        <span className='text-white text-[18.5px] font-figtree font-bold'>Buy Now</span>
                                    </a>
                                </td>
                            ))}
                        </tr>
                    </tfoot>
                </table>
            </BrowserView>
            <MobileView className='w-full'>
                <LizardBreakdownMobile />
            </MobileView>
        </section>
    );
};

export default LizardBreakdown;