import { LizCoinCTA, UniswapCTA } from "@/components/navigation/ctas";
import { BattleInTheBeyond } from "@/components/sections/home/battle-in-the-beyond";
import { ElementalLizardsPortal } from "@/components/sections/home/elemental-lizards-portal";
import { Ethlizards } from "@/components/sections/home/ethlizards";
import { FollowUs } from "@/components/sections/home/follow-us";
import { GreenRoom } from "@/components/sections/home/green-room";
import { GuardiansOfGaming } from "@/components/sections/home/guardians-of-gaming";
import { LizardLeague } from "@/components/sections/home/league";
import { LizardLaunchpad } from "@/components/sections/home/lizard-launchpad";
import { LizardLounge } from "@/components/sections/home/lizard-lounge";
import { Lizcoin } from "@/components/sections/home/lizcoin";
import { ReadyPlayerFun } from "@/components/sections/home/ready-player-fun";
import { Team } from "@/components/sections/home/team";
import { Theme } from "@/registry/themes";
import { MutableRefObject } from "react";

export interface PageSection {
    id: string;
    theme: Theme["name"];
    component: React.ForwardRefExoticComponent<Omit<SectionProps, "ref"> & React.RefAttributes<HTMLDivElement>>;
    cta: typeof LizCoinCTA;
    title: string;
    showVolumeButton?: boolean;
}

export interface SectionProps {
    isInView: boolean;
    ref: MutableRefObject<null>;
    id: string;
}

export const homeSections: PageSection[] = [
    {
        id: "lizcoin",
        theme: "lizcoin",
        component: Lizcoin,
        cta: UniswapCTA,
        title: "Lizcoin"
    },
    {
        id: "guardians",
        theme: "dark-purple",
        component: GuardiansOfGaming,
        cta: LizCoinCTA,
        title: "Guardians of Gaming"
    },
    {
        id: "readyplayer",
        theme: "cyan",
        component: ReadyPlayerFun,
        cta: LizCoinCTA,
        title: "Ready Player Fun"
    },
    {
        id: "bitb",
        theme: "dark-purple",
        component: BattleInTheBeyond,
        cta: LizCoinCTA,
        title: "Battle in the Beyond"
    },
    {
        id: "elemental",
        theme: "light-purple",
        component: ElementalLizardsPortal,
        cta: LizCoinCTA,
        title: "Elemental Lizards"
    },
    {
        id: "ethlizards",
        theme: "dark-lightblue",
        component: Ethlizards,
        cta: LizCoinCTA,
        title: "Ethlizards"
    },
    {
        id: "lounge",
        theme: "dark-lightblue",
        component: LizardLounge,
        cta: LizCoinCTA,
        title: "Lizard Lounge"
    },
    {
        id: "greenroom",
        theme: "lizcoin",
        component: GreenRoom,
        cta: LizCoinCTA,
        title: "Green Room"
    },
    {
        id: "launchpad",
        theme: "green",
        component: LizardLaunchpad,
        cta: LizCoinCTA,
        title: "Launchpad"
    },
    {
        id: "league",
        theme: "yellow",
        component: LizardLeague,
        cta: LizCoinCTA,
        title: "Lizard League",
        showVolumeButton: false
    },
    {
        id: "followus",
        theme: "black",
        component: FollowUs,
        cta: LizCoinCTA,
        title: "Follow Us"
    },
    {
        id: "team",
        theme: "black",
        component: Team,
        cta: LizCoinCTA,
        title: "The Team"
    }
];