
import logoIcon from '@/assets/images/logo-white.png';
import blurLogo from '@/assets/images/blur.png';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import EthlizardsNFTs from '@/components/sections/ethlizards/ethlizards-nfts';
import OurMission from '@/components/sections/ethlizards/our-mission';
import { useConfig } from '@/hooks/use-config';
import { useEffect } from 'react';
import OurBenefits from '@/components/sections/ethlizards/our-benefits';
import OurCollections from '@/components/sections/ethlizards/our-collections';
import MerchStore from '@/components/sections/ethlizards/merch-store';
import LizardBreakdown from '@/components/sections/ethlizards/lizard-breakdown-desktop';
import BlurBanner from '@/components/sections/ethlizards/blur';
import { FooterContent } from '@/components/footer';
import CommunityBanner from '@/components/sections/ethlizards/community';
import FAQs from '@/components/sections/ethlizards/faq';
import ReactGA from 'react-ga4';
import { useSite } from '@/site-provider';
import { Helmet } from 'react-helmet';

export const EthlizardsLayout = ({ children }: { children: React.ReactElement[] }) => {
    return <div className="relative w-full min-h-screen overflow-hidden bg-black">
        {children}
    </div>
}

const EthlizardsHeader = () => {

    return <header id="header" className="relative flex justify-between items-center w-full gap-4 h-20 lg:h-32 px-6 md:px-20 z-10">
        
        <div className="flex items-center gap-1.5 lg:gap-5">
            <img
                alt="Lizard Labs Logo Icon"
                src={logoIcon}
                className="h-[24px] w-[24px] lg:w-[34px] lg:h-[34px] logo"
            />
            <Link to="/" className="bg-black-gradient flex justify-center items-center gap-x-2.5 w-10 lg:w-auto lg:px-4 h-10 lg:h-12 rounded-[90px] text-white transition-all duration-200 hover:scale-[1.02]">
                <ArrowLeft />
                <span className='font-nekstbold text-lg !leading-5 hidden lg:block'>Back to Lizard Labs</span>
            </Link>
        </div>
        <div className="flex items-center md:gap-10">
            <a href="#collections" className='text-white text-lg font-nekstsemibold !leading-5 hidden lg:block transition-all duration-200 hover:opacity-80'>
                Our Collections
            </a>
            <a href="https://merch.lizlabs.io/" target="_blank" className='text-white text-lg font-nekstsemibold !leading-5 hidden lg:block transition-all duration-200 hover:opacity-80'>
                Merch Store
            </a>
            <a href="#breakdown" className='text-white text-lg font-nekstsemibold !leading-5 hidden lg:block transition-all duration-200 hover:opacity-80'>
                NFT Breakdown
            </a>
            <a 
                target="_blank" 
                href="https://blur.io/collection/ethlizards" 
                className="flex justify-center items-center h-[38px] lg:h-12 w-[123px] shadow-blur hover:shadow-blur-hover transition-all duration-300 rounded-[66px] border-[3px] border-[#FF6E26] hover:scale-[1.02]"
            >
                <img src={blurLogo} className="h-7" alt="Blur.io logo"/>
            </a>
        </div>
    </header>
}

function EthlizardsPage() {

    ReactGA.send({
        hitType: 'pageview',
        page: "/ethlizards",
        title: 'Ethlizards'
    });

    const [config, setConfig] = useConfig();
    const { setLoading } = useSite();
    
    useEffect(() => {
        setConfig({
            ...config,
            theme: "black"
        })
        setLoading(false);
    }, [])

    return (
        <>
        <Helmet>
            <meta property="og:image" content="/images/ethlizards-og.png" data-react-helmet="true"/>
            <meta property="og:url" content="https://lizlabs.io/ethlizards" data-react-helmet="true"/>
            <meta name="twitter:image" content="/images/ethlizards-og.png" data-react-helmet="true"/>
            <meta property="twitter:url" content="https://lizlabs.io/ethlizards" data-react-helmet="true"/>
        </Helmet>
        <EthlizardsLayout>
            <div className="absolute inset-0 h-52 bg-fade-in-purple w-full z-[1]" />
            <EthlizardsHeader />
            <main className="min-h-screen min-w-full">
                <div className='flex flex-col'>
                    <EthlizardsNFTs />
                    <OurMission />
                    <OurBenefits />
                    <OurCollections />
                    <MerchStore />
                    <LizardBreakdown />
                    <CommunityBanner />
                    <FAQs />
                    <BlurBanner />
                    <footer 
                        key="footer"
                        id="footer"
                        className='w-full'
                    > 
                        <FooterContent openAccordion={true} />
                    </footer>
                </div>
            </main>
        </EthlizardsLayout>
        </>
    );
  }
  
  export default EthlizardsPage;
  