import { Routes, Route } from "react-router-dom";
import { ThemeWrapper } from "@/components/theme-wrapper";
import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import logo from '@/assets/images/logo.png';
import darklogo from '@/assets/images/logo-dark.svg';
import { HomePage } from "@/pages/home";
import EthlizardsPage from "@/pages/ethlizards";
import LizcoinPage from "@/pages/lizcoin";
import ReactGA from 'react-ga4';
import LeaguePage from "@/pages/league";
import { useSite } from "@/site-provider";

function App() {

  ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);

  const { loading } = useSite();

  useEffect(() => {
    if (!loading) {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace("#", ""));
    
      if (!elementToScroll) document.getElementById("header")?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading])

  return (
    <ThemeWrapper>
      <AnimatePresence>
        {loading && (
          <motion.div 
            className='fixed inset-0 z-[100] h-screen w-full bg-background flex justify-center items-center'
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img src={logo} className='max-w-xs bounce lg:h-12 logo' />
            <img src={darklogo} className='max-w-xs bounce h-10 lg:h-12 dark-logo' />
          </motion.div>
        )}
      </AnimatePresence>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/ethlizards" element={<EthlizardsPage />} />
        <Route path="/lizcoin" element={<LizcoinPage />} />
        {/* <Route path="/league" element={<LeaguePage />} /> */}
      </Routes>
    </ThemeWrapper>
  );
}

export default App;
