
import cloudImg from '@/assets/images/cloud.webp'
import cloudImgMobile from '@/assets/images/cloud-mobile.webp'
import { SectionText, SectionTitle } from '@/components/ui/text';

import heroImg from '@/assets/images/heroes/4.webp'
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { slideProps, transition } from '@/components/animations/utils';
import { cn } from '@/lib/utils';
import { useSections } from '@/components/section-provider';
import { forwardRef, useRef } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import ravenVideo from '@/assets/animations/raven.webm'
import { VideoAnimation } from '@/components/animations/video';
import { SectionProps } from '@/registry/home-sections';

export const ElementalLizardsPortal = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {

    const { isInView, id } = props;

    return (
        <>
        <motion.section id={id} ref={ref} key="elp" className={cn('flex h-full w-full', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <div className='relative px-4 w-full h-2/3 md:h-full lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-start md:items-center pt-2 md:pt-0'>
                <div className='flex flex-col col-span-full gap-6 lg:gap-8 w-full px-2 lg:px-0 text-left items-start'>
                    <div className='flex flex-col gap-6 lg:gap-8 justify-center'>
                        <SectionTitle isInView={isInView}>Elemental Lizards</SectionTitle>
                        <SectionText isInView={isInView} className='md:max-w-xl text-base font-figtree !leading-6'>
                            They aren't just a profile picture, Elemental Lizards are continuously evolving RPG-like characters that take you on an epic journey into the best Web3 games, 
                            where your decisions steer the direction of lizard lore.
                        </SectionText>
                    </div>
                    <motion.button 
                        disabled
                        className={cn(buttonVariants(), 'px-6 py-7 pt-[26px] font-normal lg:font-semibold font-nekstsemibold text-base lg:text-xl !leading-5 lg:!leading-6')}
                        {...slideProps("up", isInView, true)}
                    >
                        Access Soon
                    </motion.button>
                </div>
            </div>
        </motion.section>

        <BrowserView renderWithFragment>
            <VideoAnimation
                sectionIndex={3}
                isInView={isInView}
                videoId='raven'
                className='fixed right-[10%] bottom-[20%] lg:h-[60%] z-0' 
                animate={isInView ? "show" : "hide"}
                transition={transition}
                variants={{
                    show: {
                        opacity: 1, x: 0, scale: 1.35
                    },
                    hide: {
                        opacity: 0, x: 150, scale: 1.35
                    }
                }}
            />
        </BrowserView>
        <MobileView renderWithFragment>
            <motion.img
                src={heroImg}
                className='fixed bottom-0 mx-auto z-0 w-full'
                {...slideProps("right", isInView)}
            />
        </MobileView>
        <motion.img 
            src={cloudImg} 
            className='opacity-90 fixed left-0 bottom-0 w-auto z-0 hidden lg:block' 
            {...slideProps("left", isInView)}
        />
        <motion.img 
            src={cloudImgMobile} 
            className='opacity-90 fixed left-0 bottom-0 w-auto z-0 lg:hidden block' 
            {...slideProps("left", isInView)}
        />
        </>
    );
});