import coinFaceImg from '@/assets/images/coin-face.png';
import coinTextImg from '@/assets/images/coin-text.png';
import uniswapImg from '@/assets/images/uniswap-old.png';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';


export const LizCoinCTA = () => {

    const scrollToLizoin = () => {
        document.getElementById("lizcoin")?.scrollIntoView();
    }

    return <div className='flex gap-12'>
        <div className="relative flex items-center gap-0 bg-cta-bg rounded-full p-4 pl-3 lg:pl-6 lg:p-2 px-3 md:pr-6 bg-opacity-30">
            <div className='relative hidden lg:flex w-12 h-full lg:w-16 opacity-100 pl-2'>
                <img src={coinFaceImg} className='absolute top-4 w-8 h-auto' style={{ filter: 'drop-shadow(0px 0px 14px rgba(251, 208, 32, 0.45))' }} />
                <img src={coinTextImg} className='absolute -top-1.5 -left-3 w-16 h-auto lg:w-12 lg:h-12' style={{ filter: 'drop-shadow(0px 0px 14px rgba(251, 208, 32, 0.45))' }} />
                
            </div>
            <button id="cta" onClick={scrollToLizoin} className={cn(buttonVariants({ variant: "cta", size: "lg" }), '!h-10 md:!h-12')}>
                <span className='text-base md:text-[18.5px] text-[#1F1D40] font-bold'>Get shiny Lizcoin</span>
            </button>
        </div>
    </div>
}

export const LizCoinCTALink = () => {

    return <div className='flex gap-12'>
        <div className="relative flex items-center gap-0 bg-cta-bg rounded-full p-4 pl-3 lg:pl-6 lg:p-2 px-3 md:pr-6 bg-opacity-30">
            <div className='relative w-12 h-full lg:w-16 opacity-100 pl-2'>
                <img src={coinFaceImg} className='absolute top-4 w-8 h-auto' style={{ filter: 'drop-shadow(0px 0px 14px rgba(251, 208, 32, 0.45))' }} />
                <img src={coinTextImg} className='absolute -top-1.5 -left-3 w-16 h-auto lg:w-12 lg:h-12' style={{ filter: 'drop-shadow(0px 0px 14px rgba(251, 208, 32, 0.45))' }} />
                
            </div>
            <Link id="cta" to={{ pathname: "/lizcoin", hash: "#lizcoin" }} className={cn(buttonVariants({ variant: "cta", size: "lg" }), '!h-10 md:!h-12')}>
                <span className='text-base md:text-[18.5px] text-[#1F1D40] font-bold'>Get shiny Lizcoin</span>
            </Link>
        </div>
    </div>
}

export const UniswapCTA = () => {

    return <div className='flex'>
        <div className="relative flex items-center gap-0 bg-cta-bg rounded-full lg:pl-2 py-1.5 lg:py-0 pr-2 bg-opacity-30">
            <div className='relative hidden lg:flex w-12 h-12 lg:w-[68px] lg:h-[68px] lg:mr-1'>
                <img src={uniswapImg} className='w-full' />
            </div>
            <a id="cta" href="https://app.uniswap.org/explore/tokens/ethereum/0xAF4144cd943ed5362Fed2BaE6573184659CBe6FF/" target='_blank' className={cn(buttonVariants({ variant: "cta", size: "lg" }), '!h-10 md:!h-12')}>
                <span className='text-base md:text-[18.5px] text-[#1F1D40] font-bold'>Buy $LIZ on Uniswap</span>
            </a>
        </div>
    </div>
}