import { buttonVariants } from "@/components/ui/button";
import { SectionText, SectionTitle } from "@/components/ui/text";
import { cn } from "@/lib/utils";
import { motion, useInView } from 'framer-motion';
import { useRef } from "react";
import discordImg from '@/assets/images/discord.webp';
import { slideProps } from "@/components/animations/utils";

const CommunityBanner = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="community"
            className="relative flex flex-col justify-center items-center px-6 md:px-20 text-center"
        >
            <SectionTitle isInView={isInView} className="relative lg:!text-4xl">
                Share Your Voice in Our Community
            </SectionTitle>
            <div ref={ref} className="mt-16">
                <motion.img
                    src={discordImg}
                    className="max-w-full lg:max-w-3xl"
                    {...slideProps("left", isInView)}
                />
            </div>
            <div className="flex justify-center items-center gap-x-16 mt-4 mb-10">
                <div className="flex flex-col gap-y-4">
                    <span className="text-cyan text-3xl lg:text-6xl font-nekstbold">15k+</span>
                    <span className="text-white lg:text-2xl font-nekstbold">Members</span>
                </div>
                <div className="flex flex-col gap-y-4">
                    <span className="text-cyan text-3xl lg:text-6xl font-nekstbold">3+</span>
                    <span className="text-white lg:text-2xl font-nekstbold">Years</span>
                </div>
                <div className="flex flex-col gap-y-4">
                    <span className="text-cyan text-3xl lg:text-6xl font-nekstbold">1.3M</span>
                    <span className="text-white lg:text-2xl font-nekstbold">Posts</span>
                </div>
            </div>
            <a href="https://discord.gg/lizardlabs" target="_blank" className={cn(buttonVariants({ variant: 'cta' }), "!leading-7 h-10 font-figtree")}>Join Our Discord</a>
        </section>
    );
};

export default CommunityBanner;