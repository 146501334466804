import { cn } from '@/lib/utils';
import React, { MutableRefObject } from 'react';
import { motion } from 'framer-motion';
import { AnimationDirection, slideProps } from '@/components/animations/utils';

export const SectionTitle = ({ ish1, divRef, children, className, isInView, dir }: { ish1?: boolean, divRef?: MutableRefObject<null>, children: React.ReactNode, className?: string, isInView?: boolean, dir?: AnimationDirection }) => {
    return ish1 ? (
        <motion.h1 
            ref={divRef}
            className={cn('text-3xl lg:text-7xl 3xl:text-[90px] text-foreground font-nekstbold', className)}
            {...slideProps(dir || "up", isInView)}
        >{children}</motion.h1>
    ): (
        <motion.h2 
            ref={divRef}
            className={cn('text-3xl lg:text-7xl 3xl:text-[90px] text-foreground font-nekstbold', className)}
            {...slideProps(dir || "up", isInView)}
        >{children}</motion.h2>
    );
};

export const SectionText = ({ divRef, children, className, isInView, dir }: { divRef?: MutableRefObject<null>, children: React.ReactNode, className?: string, isInView?: boolean, dir?: AnimationDirection }) => {
    return (
        <motion.p 
            ref={divRef}
            className={cn('text-base lg:text-xl text-foreground font-figtree !leading-5', className)}
            {...slideProps(dir || "up", isInView)}
        >{children}</motion.p>
    );
};