import { SectionText, SectionTitle } from '@/components/ui/text';
import shinyCoinImage from '@/assets/images/heroes/lizcoin/shiny-coins.webp';
import nftsImage from '@/assets/images/heroes/lizcoin/nfts.webp';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';

export const ShinyLizcoin = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (isInView) setHasAnimated(true);
    }, [isInView])

    return (
        <section 
            id="shiny-lizcoin"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:justify-between md:flex-row gap-y-8 pt-20 px-6 min-h-[120vh] lg:min-h-[100vh]"
        >
            <div ref={ref} className='flex justify-start mx-auto w-full z-[1] h-full'>
                
                <div className='relative flex flex-col gap-y-[32px] col-span-1 max-w-[600px] justify-start items-start'>
                    <SectionTitle dir='left' isInView={hasAnimated} className='text-[30px] lg:!text-[48px] !leading-9 lg:!leading-[52px] text-yellow'>
                        Get More Shiny Lizcoins
                    </SectionTitle>
                    <div className='flex flex-col gap-y-5 max-w-xl'>
                        <SectionText dir='left' isInView={hasAnimated} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6 max-w-xl'>
                            Guardians of Gaming™ are rewarded for active participation via simple bounties in our <a className='underline text-yellow' href="https://lizlounge.io" target='_blank'>Lizard Lounge web app</a>. 
                            We integrate the IP of top Web3 games into the lounge, and you'll see Lizards in partner games too! Together, we are forging stronger community bonds, 
                            so you can earn even more Lizcoins!
                        </SectionText>
                        <SectionText dir='left' isInView={hasAnimated} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6 z-[1]'>
                            Discover the value of <Link className='text-yellow underline' to="/ethlizards">Ethlizards NFTs</Link>
                        </SectionText>
                        <div className='w-full flex flex-col justify-end items-center lg:justify-normal z-0 my-2'>
                            <motion.img
                                src={nftsImage}
                                className='lg:ml-[6%] -mt-14 max-w-[300px] lg:max-w-[400px] lg:mr-8'
                                {...slideProps("left", hasAnimated)}
                                alt="Two example Lizard Labs NFT’s, one is a green lizard and the other has a green mushroom on his head."
                            />
                            <div className='-mt-10'>
                                <motion.a 
                                    href="https://lizlounge.io/" 
                                    target='_blank'
                                    className={cn(buttonVariants({ variant: 'default' }), 'bg-cta border-[#F9EC31] border-[3px] text-[#1F1D40] font-figtree min-h-12 !font-bold shadow-cta px-6 py-2.5 gap-2 text-[18.5px] hover:scale-[1.02] !rounded-[66px]')}
                                    {...slideProps("left", hasAnimated, true)}
                                >
                                    Become a Guardian of Gaming
                                </motion.a>
                            </div>
                        </div>
                        
                        <MobileView renderWithFragment>
                            <motion.img
                                src={shinyCoinImage}
                                className='w-full mt-2'
                                {...slideProps("right", hasAnimated)}
                                alt="A lizard with a samurai helmet holds up a sword triumphantly while mostly covered by a large pile of Lizcoin."
                            />
                        </MobileView>
                    </div>
                </div>
                <BrowserView>
                    <motion.img
                        src={shinyCoinImage}
                        className='absolute lg:-right-32 xl:-right-44 top-0 h-full'
                        {...slideProps("right", hasAnimated)}
                        alt="A lizard with a samurai helmet holds up a sword triumphantly while mostly covered by a large pile of Lizcoin."
                    />
                </BrowserView>
            </div>
        </section>
    );
};