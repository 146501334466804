
import greenRoomImg from '@/assets/images/heroes/greenroom/bg.webp'
import { SectionText, SectionTitle } from '@/components/ui/text';
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { slideProps } from '@/components/animations/utils';
import { forwardRef } from 'react';
import { SectionProps } from '@/registry/home-sections';
import greenCardAnim from '@/assets/animations/greencard.webm';
import { VideoAnimation } from '@/components/animations/video';
import { BrowserView, MobileView } from 'react-device-detect';
import greenCardMobImg from '@/assets/images/heroes/mobile/greencard.webp';

export const GreenRoom = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {

    const { isInView, id } = props;

    return (
        <>
        <motion.section id={id} ref={ref} key="greenr" className={cn(`flex h-full w-full overflow-hidden transition-colors duration-700`, isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <div className='relative px-4 w-full h-2/3 md:h-full lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-start md:items-center pt-2 md:pt-0'>
                <div className='flex flex-col col-span-full gap-6 lg:gap-8 w-full px-2 lg:px-0 text-left items-start'>
                    <div className='flex flex-col gap-6 lg:gap-8 justify-center'>
                        <SectionTitle isInView={isInView}>Green Room</SectionTitle>
                        <SectionText isInView={isInView} className='max-w-[323px] lg:max-w-xl text-base font-figtree !leading-6'>
                            Green Room is a world-class business accelerator that propels our exclusive group of clients to new heights in Web3. 
                            Backed by Lizard Labs, we provide premium marketing, media, talent and consulting services to top game studios.
                        </SectionText>
                    </div>
                    <motion.a 
                        href="https://greenroom.media/" 
                        target='_blank'
                        className={cn(buttonVariants({ variant: 'default' }), 'bg-cta border-[#F9EC31] border-[3px] text-black font-normal lg:font-semibold font-nekstsemibold shadow-cta px-6 py-7 pt-[26px] text-base lg:text-xl !leading-5 lg:!leading-6 hover:scale-[1.02] !rounded-[6px]')}
                        {...slideProps("up", isInView, true)}
                    >
                        Access Your Advantage
                    </motion.a>
                </div>
            </div>
        </motion.section>
        <motion.div 
            className='fixed right-0 bg-green-blur w-full h-1/2 top-1/2 lg:-top-[10%] lg:h-[120%] lg:w-[63%]' 
            transition={{ duration: 0.75 }}
            animate={isInView ? "show" : "hide"}
            variants={{
                show: {
                    opacity: 1
                },
                hide: {
                    opacity: 0
                }
            }}
        />
        <BrowserView renderWithFragment>
            <VideoAnimation
                src={greenCardAnim}
                sectionIndex={0}
                className='fixed right-24 bottom-0 lg:h-full z-[2]' 
                isInView={isInView}
                videoId='cog'
            />
        </BrowserView>
        <MobileView className='relative w-full'>
            <motion.img 
                key="greenCardMobImg"
                src={greenCardMobImg} 
                className='fixed h-2/5 top-[60%] ml-[20%]' 
                transition={{ duration: 0.75 }}
                animate={isInView ? "show" : "hide"}
                variants={{
                    show: {
                        opacity: 1
                    },
                    hide: {
                        opacity: 0
                    }
                }}
            />
        </MobileView>
        <motion.img 
            key="greenroombg"
            src={greenRoomImg} 
            className='fixed left-0 top-0 w-full z-0 hidden lg:flex' 
            transition={{ duration: 0.75 }}
            animate={isInView ? "show" : "hide"}
            variants={{
                show: {
                    opacity: 1
                },
                hide: {
                    opacity: 0
                }
            }}
        />
        </>
    );
});