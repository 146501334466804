import cogVideo from '@/assets/animations/cog.webm'
import cog1xVideo from '@/assets/animations/1x/cog.webm'
import whiplashVideo from '@/assets/animations/whiplash.webm'
import bitbVideo from '@/assets/animations/bitb.webm'
import bitb1xVideo from '@/assets/animations/1x/bitb.webm'
import ravenVideo from '@/assets/animations/raven.webm'
import raven1xVideo from '@/assets/animations/1x/raven.webm'
import pepeVideo from '@/assets/animations/pepe.webm'
import pepe1xVideo from '@/assets/animations/1x/pepe.webm'
import loungeVideo from '@/assets/animations/lounge.webm'
import lounge1xVideo from '@/assets/animations/1x/lounge.webm'
import thoraxVideo from '@/assets/animations/thorax.webm'
import thorax1xVideo from '@/assets/animations/1x/thorax.webm'
import lizcoinVideo from '@/assets/animations/lizcoin.webm'
import georgeVideo from '@/assets/animations/george.webm'
import george1xVideo from '@/assets/animations/1x/george.webm'

interface AnimationType {
    id: string;
    resolutions: {
        source: string;
        bounds: [number, number];
        width: number;
    }[]
}

export const animations: AnimationType[] = [
    {
        id: "cog",
        resolutions: [
            {
                source: cog1xVideo,
                bounds: [0, 1900],
                width: 1133
            },
            {
                source: cogVideo,
                bounds: [1900, 10000],
                width: 2265
            }
        ]
    },
    {
        id: "whiplash",
        resolutions: [
            {
                source: whiplashVideo,
                bounds: [0, 10000],
                width: 1300
            }
        ]
    },
    {
        id: "bitb",
        resolutions: [
            {
                source: bitb1xVideo,
                bounds: [0, 1500],
                width: 1090
            },
            {
                source: bitbVideo,
                bounds: [1500, 10000],
                width: 2179
            }
        ]
    },
    {
        id: "raven",
        resolutions: [
            {
                source: raven1xVideo,
                bounds: [0, 1200],
                width: 990
            },
            {
                source: ravenVideo,
                bounds: [1200, 10000],
                width: 1979
            }
        ]
    },
    {
        id: "pepe",
        resolutions: [
            {
                source: pepe1xVideo,
                bounds: [0, 1900],
                width: 1223
            },
            {
                source: pepeVideo,
                bounds: [1900, 10000],
                width: 2446
            }
        ]
    },
    {
        id: "lounge",
        resolutions: [
            {
                source: lounge1xVideo,
                bounds: [0, 1600],
                width: 1128
            },
            {
                source: loungeVideo,
                bounds: [1600, 10000],
                width: 2560
            }
        ]
    },
    {
        id: "thorax",
        resolutions: [
            {
                source: thorax1xVideo,
                bounds: [0, 1500],
                width: 1000
            },
            {
                source: thoraxVideo,
                bounds: [1500, 10000],
                width: 2000
            }
        ]
    },
    {
        id: "lizcoin",
        resolutions: [
            {
                source: lizcoinVideo,
                bounds: [0, 10000],
                width: 1541
            }
        ]
    },
    {
        id: "george",
        resolutions: [
            {
                source: george1xVideo,
                bounds: [0, 1450],
                width: 945
            },
            {
                source: georgeVideo,
                bounds: [1450, 10000],
                width: 1890
            }
        ]
    },
]