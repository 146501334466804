import { SectionText, SectionTitle } from '@/components/ui/text';
import lizcoinImage from '@/assets/images/heroes/lizcoin/what-is-lizcoin.webp';
import lizcoinMobileImage from '@/assets/images/heroes/lizcoin/what-is-lizcoin-mobile.webp';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { useEffect, useRef, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

export const WhatIsLizcoin = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (isInView) setHasAnimated(true);
    }, [isInView])

    return (
        <section 
            id="what-is-lizcoin"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:items-center md:justify-between md:flex-row gap-y-8 pt-20 md:pt-0 px-6 lg:min-h-[70vh]"
        >
            <div ref={ref} className='relative flex flex-col justify-center items-start gap-y-8 z-[1]'>
                <div className='flex flex-col gap-y-[32px] pt-8 lg:pt-0'>
                    <SectionTitle dir='left' isInView={hasAnimated} className='text-[32px] lg:!text-[48px] !leading-9 lg:!leading-[52px] text-yellow'>WTF is Lizcoin?</SectionTitle>
                    <div className='flex flex-col gap-y-5 max-w-xl'>
                        <SectionText dir='left' isInView={hasAnimated} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            Lizcoin is your key to Web3 gaming, backed by Lizard Labs, that raised $4,500,000 to become the go-to services and technology partner for top game studios. Lizcoin offers robust utility, through our suite of proven products:
                        </SectionText>
                        <SectionText dir='left' isInView={hasAnimated} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            <ul className='list-disc list-inside indent-4'>
                                <li>Lizard Lounge (10,000+ users)</li>
                                <li>Lizard Launchpad ($6M+ raised in 3 years)</li>
                                <li>Battle in the Beyond (71% platform dominance)</li>
                                <li>Green Room ($400,000+ in sales)</li>
                                <li>Lizard League (Coming SoonTM)</li>
                            </ul>
                        </SectionText>
                        <SectionText dir='left' isInView={hasAnimated} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            We provide a strategic platform that accelerates the growth of select gaming clients.
                        </SectionText>
                    </div>
                </div>
            </div>
            <BrowserView renderWithFragment>
                <motion.img
                    src={lizcoinImage}
                    className='absolute -right-64 w-full'
                    {...slideProps("right", hasAnimated)}
                    alt="Lizards gather around in wonder to look at a new shiny Lizcoin on a pedestal"
                />
            </BrowserView>
            <MobileView renderWithFragment>
                <div className='h-48 -mt-12'>
                    <motion.img
                        src={lizcoinMobileImage}
                        className='absolute left-0 right-0'
                        {...slideProps("right", hasAnimated)}
                        alt="Lizards gather around in wonder to look at a new shiny Lizcoin on a pedestal"
                    />
                </div>
            </MobileView>
        </section>
    );
};