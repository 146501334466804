import ctaFrame from '@/assets/images/cta-frame.png';
import topaz from '@/assets/images/topaz.webp';
import coins from '@/assets/images/coins.png';
import circle from '@/assets/images/circle.png';
import { useLizcoinCTA } from '@/components/sections/lizcoin/cta/provider';
import { motion, useAnimate } from 'framer-motion';
import { ReactNode, useEffect } from 'react';
import { Button, buttonVariants } from '@/components/ui/button';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Icons } from '@/components/ui/icons';

const StageText = ({ text }: { text: string | ReactNode }) => {
    return <span className='text-white font-figtree font-bold text-xs lg:text-base leading-[15px] line-clamp-none lg:leading-5'>{text}</span>
}

const StageCTA = ({ text, onClick }: { text: string | JSX.Element, onClick: any }) => {
    return <motion.button
        onClick={onClick}
        className={cn(buttonVariants({ variant: 'default' }), 'absolute left-0 -bottom-1.5 lg:-bottom-4 bg-cta-black border-[#6494EB] border-[3px] text-white font-figtree !font-bold shadow-black-cta px-6 lg:py-[10px] h-[40px] lg:h-[48px] !text-base lg:!text-[18.5px] leading-7 hover:scale-[1.02] !rounded-[66px]')}
    >
        <span className='-mt-0.5'>{text}</span>
    </motion.button>
}

const tweet = `
Just grabbed a bag of shiny Lizcoins 🪙%0A%0A

Nothing beats owning a part of the Nexus of web3 gaming%0A%0A

Haven't secured your bag yet? Head over to Uniswap and grab some!%0A%0A

Learn more about $LIZ:%0A
https://lizlabs.io/lizcoin
`;

const StageContent = () => {

    const { stage, setStage, setClosed } = useLizcoinCTA();

    const goToLounge = () => {
        window.open("https://lizlounge.io/", "_blank");
        setClosed(false);
    }

    const shareLizcoin = () => {
        window.open(`https://x.com/intent/tweet?text=${tweet}`, "_blank");
        setStage(1);
    }

    return <>
        {stage === 0 && (
            <>
                <StageText text={<>Let your friends know about $LIZ. <br/>Spread the word and share on X! 🚀</>} />
                <StageCTA text={<span className='flex justify-center items-center gap-x-2'>Share on <Icons.twitterX className='w-6 h-6 fill-white' color='white'/></span>} onClick={shareLizcoin}/>
            </>
        )}
        {stage === 1 && (
            <>
            <StageText text="Did you know that you can stake your $LIZ in the Lizard Lounge? You can earn more $LIZ, win partner rewards and more!" />
            <StageCTA text="Sign Up to the Lounge" onClick={goToLounge}/>
        </>
        )}
    </>
}

export const LizcoinCTABanner = () => {

    const [scope, animate] = useAnimate();
    const { setStage, stage, closed, setClosed } = useLizcoinCTA();

    useEffect(() => {
        // setStage(0)
        // setClosed(false)
        if (closed || stage >= 2) return;
        animate(".cta-wrapper", { opacity: 1, y: 0 }, { duration: 0.5 });
    }, [stage, closed]);

    if (closed || stage >= 2) return null;

    return <div ref={scope} className='hidden lg:flex fixed -bottom-0 lg:bottom-10 right-8 lg:right-14 w-full max-w-[300px] lg:max-w-[617px] min-h-[136px] z-20'>
        <div className='relative w-full h-full cta-wrapper opacity-0 min-h-[75px] lg:min-h-[136px] drop-shadow-lizcoin-cta'>
            <div 
                className='absolute bottom-0 right-0 lg:max-w-[551px] w-full h-content bg-contain bg-no-repeat pl-8 pt-1 pr-16 lg:pl-[35px] lg:pr-[131px] lg:pt-[22px]'
                style={{ backgroundImage: `url(${ctaFrame})` }}
            >
                <img src={coins} className='absolute -right-2 -bottom-2 lg:bottom-[2px] max-w-[160px] lg:max-w-[283px]' />
                <img src={topaz} className={cn('absolute -right-12 lg:-right-10 -bottom-1.5 max-w-[120px] lg:max-w-[209px] scale-110', stage === 1 && " -right-12 lg:-right-16")} />
                <Button
                    className='rounded-full bg-transparent hover:bg-transparent !w-8 !h-8 lg:!w-[45px] lg:!h-[45px] absolute -left-4 -top-4 lg:-left-5 lg:-top-5 p-0 bg-cover bg-no-repeat bg-center'
                    style={{ backgroundImage: `url(${circle})` }}
                    onClick={() => setClosed(true)}
                >
                    <X className='w-6 h-6' color='white' />
                </Button>
                <StageContent />
            </div>
            
        </div>
    </div>
}