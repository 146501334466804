import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { SectionTitle } from "@/components/ui/text";
import { useInView } from 'framer-motion';
import { useRef } from "react";

const faqs = [
    {
        question: "How do portfolio revenue rewards work?",
        answer: "Genesis and Venture holders can stake their Ethlizards and complete the minimum bounty participation for a share of net revenue from portfolio liquidations."
    },
    {
        question: "How do I Stake?",
        answer: <>It's easy, go to <a target="_blank" href="https://staking.ethlizards.io/" className="text-[#FDB10D]">https://staking.ethlizards.io/</a> and lock up that lizard!</>
    },
    {
        question: "What happens when I stake?",
        answer: <>
            When you stake an Ethlizards Genesis or Venture NFT, then it gets sent to the staking contract and locked for a minimum of 90 days. 
            In exchange, you get a Locked Lizard Token (LLZ) that acts as a temporary risk-free NFT allowing you to still verify Collabland and Vulcan on Discord, 
            vote in governance and other on-chain activities. 
            Your valuable Ethlizards NFT is stored safely in the staking contract and your LLZ can be used in any hot or cold wallet without 
            any risk because it can be recalled via the staking dashboard. Click here for more info on staking. 
            <a target="_blank" href="https://info.ethlizards.io/" className="text-[#FDB10D] pl-1">https://info.ethlizards.io/</a>
        </>
    },
    {
        question: "How do I buy Ethlizards NFTs?",
        answer: "Our NFTs can be found on Blur and Magic Eden. You can purchase directly through the buttons shown below! 👇"
    }
]

const FAQs = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="faqs"
            className="relative flex flex-col justify-center items-center px-6 md:px-20 text-center mt-32"
        >
            <SectionTitle isInView={isInView} className="relative lg:!text-6xl">
                FAQ
            </SectionTitle>
            <div ref={ref} className="flex justify-center items-center my-8 lg:my-20">
                <Accordion type="multiple" className="lg:w-[800px]">
                    {faqs.map((faq, i) => (
                        <AccordionItem key={`faq_${i}`} value={`faq_${i}`} asChild>
                            <div className="rounded-lg px-5 py-[22px] bg-faq my-2">
                                <AccordionTrigger className="text-white text-left text-base lg:max-w-full lg:text-2xl !leading-7 font-nekstbold hover:no-underline">
                                    {faq.question}
                                </AccordionTrigger>
                                <AccordionContent className="text-white font-figtree !leading-5 text-left text-base">
                                    {faq.answer}
                                </AccordionContent>
                            </div>
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
        </section>
    );
};

export default FAQs;