import { useConfig } from '@/hooks/use-config';
import { homeSections } from '@/registry/home-sections';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

interface SectionContextType {
    section: number;
    setSection: (state: number) => void;
    showFooter: boolean;
    setShowFooter: (state: boolean) => void;
    showVolume: boolean;
    isMuted: boolean;
    setIsMuted: (state: boolean) => void;
    setShowVolume: (state: boolean) => void;
    scrollTo: (state: number, straighTo?: number) => void;
}

const defaultValues = {
    section: 0,
    setSection: () => {},
    showFooter: false,
    setShowFooter: () => {},
    showVolume: false,
    isMuted: false,
    setShowVolume: () => {},
    setIsMuted: () => {},
    scrollTo: () => {}
}

export const opacityVariants = (opacity: number) => {
    return {
        view: {
            opacity: opacity
        },
        hide: {
            opacity: 0
        }
    }
}

const SectionContext = createContext<SectionContextType>(defaultValues);

export const useSections = () => {
    return useContext(SectionContext);
}

export const SectionProvider = ({ children }: { children: React.ReactNode }) => {

    const [config, setConfig] = useConfig();

    const [section, setSection] = useState<number>(defaultValues.section);
    const [showFooter, setShowFooter] = useState<boolean>(defaultValues.showFooter);
    const [isMuted, setIsMuted] = useState<boolean>(true);
    const [showVolume, setShowVolume] = useState<boolean>(defaultValues.showVolume);

    const isAnimatingRef = useRef(false);

    const scrollTo = useCallback((adj: number, straighTo?: number) => {
        if (isAnimatingRef.current) return;

        if (showFooter && adj < 0) {
            isAnimatingRef.current = false;
            setShowFooter(false);
            return;
        }

        const canBack = section > 0;
        const canForward = section < homeSections.length-1;
        
        if (adj < 0 && !canBack) return;
        if (adj > 0 && !canForward && showFooter) return;

        if (section < (homeSections.length -1) || adj === -1 || straighTo !== undefined) {
            const goto = straighTo !== undefined ? straighTo : section + adj;
            document.getElementById(homeSections[goto].id)?.scrollIntoView();
        } else if ((homeSections.length -1) === section && adj === 1) {
            setShowFooter(true);
        }
    }, [section, showFooter, isAnimatingRef]);

    useEffect(() => {
        setSection(0);
        setConfig({
            ...config,
            theme: homeSections[0].theme
        });
    }, [])

    useEffect(() => {
        setShowVolume((homeSections[section].showVolumeButton && !isMobile) || false);
    }, [section])

    return (
        <SectionContext.Provider value={{
            section,
            setSection,
            showVolume,
            setShowVolume,
            showFooter,
            isMuted,
            setIsMuted,
            setShowFooter,
            scrollTo
        }}>
            {children}
        </SectionContext.Provider>
    );
};