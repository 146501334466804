import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface CTAContextType {
    closed: boolean;
    setClosed: (s: boolean) => void;
    stage: number;
    setStage: (s: number) => void;
}

const defaultValues = {
    closed: false,
    setClosed: () => {},
    stage: 0,
    setStage: () => {}
}

const LizcoinCTAContext = createContext<CTAContextType>(defaultValues);

export const useLizcoinCTA = () => {
    return useContext(LizcoinCTAContext);
}

export const LizcoinCTAProvider = ({ children }: { children: React.ReactNode }) => {

    const [closed, updateClosed] = useState(false);
    const [stage, updateStage] = useState(0);

    useEffect(() => {
        const localClosed = localStorage.getItem("lizcoin_cta_stage_closed");
        updateClosed(localClosed ? localClosed === "true" : defaultValues.closed);
        updateStage(0);
    }, [])

    const setClosed = useCallback((s: boolean) => {
        updateClosed(s);
        localStorage.setItem("lizcoin_cta_stage_closed", String(s));
    }, [updateStage])

    const setStage = useCallback((newStage: number) => {
        updateStage(newStage);
    }, [updateStage])

    return (
        <LizcoinCTAContext.Provider value={{
            stage,
            setStage,
            closed,
            setClosed
        }}>
            {children}
        </LizcoinCTAContext.Provider>
    );
};