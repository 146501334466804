import { buttonVariants } from "@/components/ui/button";
import { SectionText, SectionTitle } from "@/components/ui/text";
import { cn } from "@/lib/utils";
import { motion, useInView } from 'framer-motion';
import { useRef } from "react";
import blurImg from '@/assets/images/blur.png';

export const collections = [
    {
        id: "genesis",
        link: "https://blur.io/collection/ethlizards-genesis",
        buttonTitle: "Buy Genesis NFTs"
    },
    {
        id: "venture",
        link: "https://blur.io/eth/collection/ethlizards",
        buttonTitle: "Buy Venture NFTs"
    },
    {
        id: "larp",
        link: "https://blur.io/eth/collection/larp-suits",
        buttonTitle: "Buy LARP NFTs"
    }
]

const BlurBanner = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <section 
            id="blur"
            className="relative flex flex-col justify-center items-center px-6 md:px-20 text-center h-[552px]"
        >
            <div className="absolute inset-0 bg-blur opacity-10 w-full h-full" />
            <img 
                src={blurImg}
                className="absolute align-middle self-center opacity-20 max-w-[1160px] w-full"
            />
            <SectionTitle isInView={isInView} className="relative lg:!text-4xl">NFTs Available on BLUR</SectionTitle>
            <SectionText isInView={isInView} className="mt-6 mb-8 font-nekstbold text-lg !leading-6">You can get your hands on some Ethlizards NFTs directly through BLUR!</SectionText>

            <div ref={ref} className="flex flex-col lg:flex-row gap-4">
                {collections.map((col, x) => (
                    <motion.a 
                        key={`col_${col.id}`} 
                        href={col.link}
                        target="_blank"
                        className={cn(buttonVariants({ variant: "cta-no-anim" }), "h-10")}
                        transition={{ duration: 1.5, bounceStiffness: 5, bounceDamping: 5, bounce: 0.4, type: 'spring', delay: x * 0.15 }}
                        animate={isInView ? "show" : "hide"}
                        variants={{
                            show: {
                                opacity: 1,
                                y: 0
                            },
                            hide: {
                                opacity: 0,
                                y: -80
                            }
                        }}
                    >
                        {col.buttonTitle}
                    </motion.a>
                ))}
            </div>
        </section>
    );
};

export default BlurBanner;