import { LizCoinCTA } from '@/components/navigation/ctas';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface CTA extends React.ReactElement<HTMLDivElement> {
    isScroll?: boolean;
}

interface SiteContextType {
    loading: boolean;
    setLoading: (state: boolean) => void;
    Cta: CTA;
    setCta: (state: CTA) => void;
}

const defaultValues = {
    loading: true,
    setLoading: () => {},
    Cta: <></>,
    setCta: () => {}
}

const SiteContext = createContext<SiteContextType>(defaultValues);

export const useSite = () => {
    return useContext(SiteContext);
}

export const SiteProvider = ({ children }: { children: React.ReactNode }) => {

    const [loading, setLoading] = useState<boolean>(defaultValues.loading);
    const [Cta, setCta] = useState<CTA>(defaultValues.Cta);

    return (
        <SiteContext.Provider value={{
            loading, 
            setLoading,
            Cta,
            setCta
        }}>
            {children}
        </SiteContext.Provider>
    );
};