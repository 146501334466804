
export type AnimationDirection = "left" | "right" | "up" | "down";

export const transition = { duration: 1, bounceStiffness: 1, bounceDamping: 2, bounce: 0.2, type: 'spring' };

export const slideProps = (direction: AnimationDirection, isInView?: boolean, scaleHover?: boolean) => {

    const onHover = {
        whileHover: scaleHover ? {
            scale: 1.03,
            transition: { duration: 0.2 },
        } : {}
    }

    if (direction === "left") {
        return {
            initial: "hide",
            variants: {
                show: {
                    opacity: 1, 
                    x: 0
                },
                hide: {
                    opacity: 0, 
                    x: -300
                }
            },
            ...onHover,
            animate: isInView ? "show" : "hide",
            transition: transition
        } 
    }

    if (direction === "right") {
        return {
            initial: "hide",
            variants: {
                show: {
                    opacity: 1, 
                    x: 0
                },
                hide: {
                    opacity: 0, 
                    x: 300
                }
            },
            ...onHover,
            animate: isInView ? "show" : "hide",
            transition: transition
        } 
    }

    if (direction === "up") {
        return {
            initial: { opacity: 0, y: 180 },
            variants: {
                show: {
                    opacity: 1, 
                    y: 0
                },
                hide: {
                    opacity: 0, 
                    y: -180
                }
            },
            ...onHover,
            animate: isInView ? "show" : "hide",
            transition: transition
        } 
    }

    return {
        initial: { opacity: 0, y: -180 },
        variants: {
            show: {
                opacity: 1, 
                y: 0
            },
            hide: {
                opacity: 0, 
                y: 180
            }
        },
        ...onHover,
        animate: isInView ? "show" : "hide",
        transition: transition
    } 
}