import { SectionText, SectionTitle } from '@/components/ui/text';
import lizcoinImage from '@/assets/images/heroes/lizcoin/why-lizcoin.webp';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { useEffect, useRef, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import cogImg from '@/assets/images/heroes/lizcoin/cog.webp';

export const CommunityFirst = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (isInView) setHasAnimated(true);
    }, [isInView])

    return (
        <section 
            id="why-lizcoin"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:items-center md:justify-between md:flex-row gap-y-8 pt-20 md:pt-0 px-6 min-h-[130vh] lg:min-h-[70vh]"
        >
            <div ref={ref} className='relative grid grid-cols-10 justify-center items-center w-full z-[1]'>
                <BrowserView renderWithFragment>
                    <motion.img
                        src={lizcoinImage}
                        className='col-span-5'
                        {...slideProps("left", hasAnimated)}
                        alt="A very hungry orange lizard laying down and taking a bite out of one of his shiny Lizcoins."
                    />
         
                </BrowserView>
                <div className='flex flex-col gap-y-[32px] col-span-full lg:col-span-5 lg:pl-10 pb-32 lg:pb-0'>
                    <MobileView renderWithFragment>
                        <motion.img
                            src={lizcoinImage}
                            className='w-full -mb-6'
                            {...slideProps("left", hasAnimated)}
                            alt="A very hungry orange lizard laying down and taking a bite out of one of his shiny Lizcoins."
                        />
                    </MobileView>
                    <SectionTitle dir='right' isInView={hasAnimated} className='text-[32px] lg:!text-[48px] !leading-9 lg:!leading-[52px] text-yellow'>Community First</SectionTitle>
                    <div className='flex flex-col gap-y-5 max-w-xl'>
                        <SectionText dir='right' isInView={hasAnimated} className='text-foreground font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            Lizard Labs is staying true to our roots as a community-driven project. We are thrilled to launch Lizcoin exclusively on decentralized exchanges (DEXs) like Uniswap.
                        </SectionText>
                        <SectionText dir='right' isInView={hasAnimated} className='text-foreground font-bold font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            43% of the total Lizcoin supply is allocated to our community
                        </SectionText>
                        <SectionText dir='right' isInView={hasAnimated} className='text-foreground font-bold font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            10% are immediately claimable by our community
                        </SectionText>
                        <SectionText dir='right' isInView={hasAnimated} className='text-foreground font-bold font-figtree text-lg lg:text-xl !leading-5 lg:!leading-6'>
                            2x future Lizcoin airdrops for loyal Ethlizards NFT stakers
                        </SectionText>
                    </div>
                    
                </div>
            </div>
        </section>
    );
};