import genesis from '@/assets/images/breakdown/genisis.png';
import chi from '@/assets/images/breakdown/chi.png';
import venture from '@/assets/images/breakdown/venture.png';
import larp from '@/assets/images/breakdown/larp.png';
import { SectionText, SectionTitle } from '@/components/ui/text';
import { Check, LucideCheck, X, XIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { BrowserView, MobileView } from 'react-device-detect';
import { Carousel, CarouselContent, CarouselDots, CarouselItem } from '@/components/ui/carousel';

interface BenefitType {
    title: string;
    values: any[];
}

interface BenefitTableType {
    image: React.ReactElement<HTMLImageElement>[];
    index: number;
    title: string;
    borderClass: string;
    bgClassName: string;
    bgColor: string;
    dropShadow: string;
    textColor: string;
    url: string;
}

const benefits: BenefitType[] = [
    {
        title: "Ethlizards Membership",
        values: [
            "LVL 1", 
            "LVL 2", 
            "LVL 3"
        ]
    },
    {
        title: "Portfolio Revenue Rewards",
        values: ["", "1x", "2x"]
    },
    {
        title: "Exclusive Discord Access",
        values: ["LVL 1", "LVL 2", "LVL 3"]
    },
    {
        title: "Exclusive Whitelist Raffles",
        values: ["LVL 1", "LVL 2", "LVL 2"]
    },
    {
        title: "Lizard Launchpad Access",
        values: ["4% Fees", "0% Fees", "0% Fees"]
    },
    {
        title: "Lizard Launchpad Allocations",
        values: ["1x", "2x", "4x"]
    },
    {
        title: "Elemental Lizard Airdrops",
        values: [false, true, true]
    },
    {
        title: "Lizcoin $LIZ Airdrops",
        values: [false, true, true]
    },
    {
        title: "Governance Voting",
        values: [false, true, true]
    }
];

const tables: BenefitTableType[] = [
    {
        image: [
            <img key="larp_nft_1" src={larp} className='absolute bottom-0 left-10 lg:left-4 w-20 lg:w-20' />,
            <img key="larp_nft_2" src={chi} className='absolute bottom-0 right-8 lg:right-0 w-40 lg:w-[150px]' />
        ],
        index: 0,
        title: "Ethlizards LARP Suit & Special Edition NFTs",
        borderClass: "border-benefit-purple",
        bgClassName: "bg-benefit-purple",
        bgColor: 'bg-purple',
        textColor: 'text-purple',
        dropShadow: "",
        url: "https://blur.io/eth/collection/larp-suits"
    },
    {
        image: [<img key="venture_nft_1" src={venture} className='absolute bottom-0 max-h-28 left-20 lg:left-6' />],
        index: 1,
        title: "Ethlizards: Venture Lizards",
        borderClass: "border-benefit-blue",
        bgClassName: "bg-benefit-blue",
        bgColor: 'bg-blue',
        textColor: 'text-blue',
        dropShadow: "drop-shadow(0px 4px 34px #5E78D8)",
        url: "https://blur.io/eth/collection/ethlizards"
    },
    {
        image: [<img key="vgenesis_nft_1" src={genesis} className='absolute bottom-0 max-h-28 left-20 lg:left-6' />],
        index: 2,
        title: "Ethlizards: Genesis Lizards",
        borderClass: "border-benefit-yellow",
        bgClassName: "bg-benefit-yellow",
        bgColor: 'bg-yellow',
        textColor: 'text-yellow',
        dropShadow: "drop-shadow(0px 4px 34px #FDB10D)",
        url: "https://blur.io/collection/ethlizards-genesis"
    }
];

const CollectionTableMobile = ({ collection }: { collection: BenefitTableType }) => {

    return <div className={cn('flex flex-col items-center w-full justify-center')} style={{
        filter: collection.dropShadow || "",
    }}>
        <div className="relative h-32 w-full">
            {collection.image}
        </div>
        <div className={cn("relative w-full border-[3px] bg-origin-border rounded-[20px] border-opacity-50 pl-6 pb-2", collection.borderClass)}>
            <div className={cn("absolute inset-0 w-full h-full opacity-50", collection.bgClassName)} />
            <div className={cn('flex flex-col gap-y-2 items-center px-4 pt-6 pb-4 justify-center')}>
                <span className="text-white text-xl font-nekstbold">{collection.title}</span>
            </div>
            {benefits.sort((b) => b.values[collection.index] ? -1 : 1).map((benefit, y) => {
                const hasValue = benefit.values[collection.index] || false;
                return <div key={`benefit_${benefit.title}`} className={cn('flex flex-col my-3')}>
                    <div className='flex items-center gap-x-4'>
                        <div className={cn('w-6 h-6 rounded-full flex justify-center items-center', hasValue ? collection.bgColor : `${collection.bgColor} bg-opacity-20`)}>
                            {hasValue ? <Check className='text-white w-4 h-4' /> : <XIcon className='text-white w-5 h-5' />}
                        </div>
                        <div className='flex flex-col gap-y-1 items-start'>
                            <span className={cn("text-base font-nekstbold leading-5", hasValue ? 'text-white' : 'text-white/50')}>{benefit.title}</span>
                            {hasValue && <span className={cn("text-base font-nekstbold leading-4", collection.textColor)}>{benefit.values[collection.index]}</span>}
                        </div>
                    </div>
                </div>
            })}
        </div>
        <a 
            target='_blank'
            href={collection.url}
            className={cn(
                'flex items-center justify-center rounded-[40px] bg-opacity-50 w-full py-2.5 border-benefit-purple mt-4 border hover:scale-[1.02] transition-all duration-200', 
                collection.bgClassName, 
                collection.borderClass,
                collection.index === tables.length-1 ? "shadow-buy-now" : ""
            )}
        >
            <span className='text-white text-[18.5px] font-figtree font-bold'>Buy Now</span>
        </a>
    </div>
}

const LizardBreakdownMobile = () => {
    return (
        <Carousel>
            <CarouselContent className='flex gap-x-4'>
                {tables.sort((a,b) => b.index - a.index).map((tbl) => (
                    <CarouselItem key={`col_table_${tbl.index}`} className='w-full'>
                        <CollectionTableMobile collection={tbl} />
                    </CarouselItem>
                ))}
            </CarouselContent>
            <CarouselDots />
        </Carousel>
    );
};

export default LizardBreakdownMobile;