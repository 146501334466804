import { SectionTitle } from '@/components/ui/text';
import benefit1Img from '@/assets/images/benefits/1.webp';
import benefit2Img from '@/assets/images/benefits/2.webp';
import benefit3Img from '@/assets/images/benefits/3.webp';
import { useEffect, useRef, useState } from 'react';
import { useInView,motion } from 'framer-motion';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { Carousel, CarouselApi, CarouselContent, CarouselDots, CarouselItem } from '@/components/ui/carousel';


interface BenefitType {
    image: string;
    title: string;
    description: string;
}

const benefits: BenefitType[] = [
    {
        image: benefit1Img,
        title: "Earn Rewards",
        description: "Unlock more benefits by staking, holding, and completing bounties with your lizard. Staked NFTs get exclusive $LIZ airdrops, free Elemental Lizard heroes, and rewards from Portfolio revenue."
    },
    {
        image: benefit2Img,
        title: "Invest Smarter",
        description: "Ethlizard NFT holders receive special perks geared towards Web3 builders and investors. Gain access to holder-only white list raffles, pre-seed and seed opportunities via our launchpad with zero fees!"
    },
    {
        image: benefit3Img,
        title: "Influence Innovation",
        description: "Shape the future of Web3 gaming as a member of the most recognizable communities in the industry. Join the most knowledgeable experts in the sector with access to our exclusive Discord, governance voting, and bragging rights that you are more than a mere mortal, you're an Ethlizard!"
    }
]

const BenefitCard = ({ benefit }: { benefit: BenefitType }) => {  

    return <>
        <div 
            className='h-[185px] w-full bg-no-repeat bg-cover' 
            style={{ backgroundImage: `url(${benefit.image})` }}
        />
        <div className='flex flex-col gap-y-5 pb-6 px-6'>
            <span className='font-nekstbold text-2xl text-foreground !leading-7'>{benefit.title}</span>
            <span className='text-base font-figtree text-foreground !leading-5'>{benefit.description}</span>
        </div>
    </>
}

const OurBenefits = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    const [api, setApi] = useState<CarouselApi>()

    return (
        <section 
            id="benefits"
            className="relative flex flex-col justify-start items-center mb-28 gap-y-8 px-4 lg:pl-6 md:px-20 text-center"
        >
            <div className='hidden lg:block absolute top-[10%] w-[500px] h-[500px] blur-[122px] rounded-full bg-[#5E78D8] opacity-40' />
            <SectionTitle isInView={isInView} className="relative text-2xl lg:!text-5xl !leading-7 lg:!leading-[52px]">Your Benefits</SectionTitle>
            <BrowserView>
                <div ref={ref} className='relative grid md:grid-cols-3 gap-y-12 md:gap-x-8 justify-center items-stretch max-w-6xl lg:py-20'>
                    {benefits.map((bnft, x) => (
                        <motion.div 
                            key={bnft.title} 
                            className="flex flex-col bg-benefit-card text-center gap-y-5 rounded-[10px] overflow-hidden"
                            transition={{ duration: 1.5, bounceStiffness: 5, bounceDamping: 5, bounce: 0.4, type: 'spring', delay: x * 0.15 }}
                            animate={isInView ? "show" : "hide"}
                            variants={{
                                show: {
                                    opacity: 1,
                                    y: 0
                                },
                                hide: {
                                    opacity: 0,
                                    y: -80
                                }
                            }}
                        >
                            <BenefitCard key={`mission_d_${bnft.title}`} benefit={bnft} />
                        </motion.div>
                    ))}
                </div>
            </BrowserView>
            <MobileView className='w-full'>
                <Carousel ref={ref} setApi={setApi}>
                    <CarouselContent className='flex gap-x-4'>
                    {benefits.map((bnft, x) => (
                        <CarouselItem key={bnft.title} className='flex flex-col w-full bg-benefit-card text-center gap-y-5 rounded-[10px] overflow-hidden'>
                            <BenefitCard key={`mission_${bnft.title}`} benefit={bnft} />
                        </CarouselItem>
                    ))}
                    </CarouselContent>
                    <CarouselDots />
                </Carousel>
            </MobileView>
        </section>
    );
};

export default OurBenefits;