import { motion } from "framer-motion";
import { itemVariants, MenuItem } from "./menu-item";
import { homeSections } from "@/registry/home-sections";
import { useSections } from "@/components/section-provider";

const variants = {
  open: {
    transition: { staggerChildren: 0.04, delayChildren: 0.15 }
  },
  closed: {
    transition: { staggerChildren: 0.02, staggerDirection: -1 }
  }
};

export const Navigation = ({ sectionPress }: { sectionPress: (x: number) => void }) => {

  const { setShowFooter } = useSections();

  return <motion.ul variants={variants} className="flex flex-col w-full h-full justify-evenly items-center z-[51] xl:py-4 -ml-8 lg:-ml-10">
    {homeSections.map((s, x) => (
      <MenuItem key={`menu_item_${s.id}`} i={x} section={s} sectionPress={sectionPress} />
    ))}
    <motion.li
      key="menu_item_footer"
      variants={itemVariants}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 1 }}
      className="relative flex items-center font-nekstsemibold text-xl lg:text-2xl !leading-6 lg:!leading-7 hover:cursor-pointer text-white pl-8 lg:pl-10"
      onClick={() => setShowFooter(true)}
    >
      Quick Links
    </motion.li>
  </motion.ul>
};