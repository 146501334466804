import { SectionText, SectionTitle } from '@/components/ui/text';
import lizcoinHero from '@/assets/images/heroes/7.webp'
import { buttonVariants } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { slideProps } from '@/components/animations/utils';
import { forwardRef } from 'react';
import Stars from '@/components/stars';
import { SectionProps } from '@/registry/home-sections';
import { VideoAnimation } from '@/components/animations/video';
import { BrowserView, MobileView } from 'react-device-detect';

export const Lizcoin = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {

    const { isInView, id } = props;

    return (
        <>
        <motion.section id={id} ref={ref} key="fllowus" className={cn('flex h-full w-full overflow-hidden', isInView && "z-[1]")} animate={isInView ? { opacity: 1 } : { opacity: 0 }}>
            <div className='relative px-4 w-full h-2/3 md:h-full lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl 3xl:max-w-[100rem] grid grid-cols-5 items-start md:items-center pt-2 md:pt-0'>
                <div className='flex flex-col col-span-full gap-6 lg:gap-8 w-full px-2 lg:px-0 text-left items-start'>
                    <div className='flex flex-col gap-6 lg:gap-8 justify-center'>
                        <SectionTitle isInView={isInView}>
                            Lizcoin ($LIZ)
                        </SectionTitle>
                        <SectionText isInView={isInView} className='max-w-[323px] md:max-w-md xl:max-w-lg 2xl:max-w-[37rem] text-base font-figtree !leading-6'> 
                            Lizcoin is the key to unleashing Web3 gaming's potential. Get exposure to top Web3 gaming tokens, while unlocking the power of wagering in the best Web3 games.
                        </SectionText>
                    </div>
                    <motion.a 
                        href="/lizcoin" 
                        className={cn(buttonVariants({ variant: 'default' }), 'bg-cta border-[#F9EC31] border-[3px] text-black font-normal lg:font-semibold font-nekstsemibold shadow-cta px-6 py-7 pt-[26px] text-base lg:text-xl !leading-5 lg:!leading-6 hover:scale-[1.02] !rounded-[6px]')}
                        {...slideProps("up", isInView, true)}
                    >
                        Learn about $LIZ
                    </motion.a>
                </div>
            </div>
        </motion.section>
        <Stars 
            className='fixed w-full h-full inset-0 opacity-30 z-0 fill-[#2C70F4]' 
            animate={isInView ? { opacity: 0.3 } : { opacity: 0 }}
        />
        <motion.div animate={isInView ? { opacity: 1 } : { opacity: 0 }} className='bg-eclipse fixed left-0 -top-[25%] h-[150%] w-[120%] rounded-full z-0' />
        <BrowserView renderWithFragment>
            <VideoAnimation
                isInView={isInView}
                videoId='lizcoin'
                className='fixed right-[2%] bottom-[15%] 2xl:right-[6%] lg:h-[70%] z-0' 
                {...slideProps("right",isInView)}
            />
        </BrowserView>
        <MobileView renderWithFragment>
              <motion.img
                  src={lizcoinHero}
                  className='fixed bottom-8 left-[12.5%] z-0 w-3/4'
                  {...slideProps("right", isInView)}
              />
          </MobileView>
        </>
    );
});