import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import { slideProps } from '@/components/animations/utils';
import { SectionTitle } from '@/components/ui/text';
import Stars from '@/components/stars';
import LinearStars from '@/components/linear-stars';
import logo from '@/assets/images/white-logo.svg';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';

export const PitchDeck = () => {

    const ref = useRef(null);
    const isInView = useInView(ref);

    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (isInView) setHasAnimated(true);
    }, [isInView])

    return (
        <section 
            id="litepaper"
            className="relative z-[1] md:z-0 flex flex-col justify-center items-start md:justify-between md:flex-row gap-y-8 pt-20 px-6 md:px-20"
        >
            <div ref={ref} className='flex justify-center mx-auto w-full z-[1] h-full'>
                
                <motion.div className='relative flex flex-col gap-y-[42px] col-span-1 w-full max-w-[883px] justify-center items-center' {...slideProps("up", hasAnimated)}>
                    <SectionTitle isInView={hasAnimated} className='text-[28px] text-center lg:!text-[48px] !leading-9 lg:!leading-[52px] text-yellow'>Lizcoin Explained with Pretty Pictures</SectionTitle>
                    <div className='relative flex flex-col w-full'>
                        <LinearStars className="absolute -bottom-[33.3%] w-full h-[155px] lg:h-[419px]"/>
                        <div className="relative aspect-[9_/_5.6] rounded-xl bg-[#1f1e40] w-full p-4">
      
                            <iframe 
                                src="https://docs.google.com/presentation/d/e/2PACX-1vQb6D4eP3ndPRfkmMad_6MgG0G8tvHnvVKyzNN9lFVBhSmTldNuxLse412aFO9Bev9RmKIQ__zZuXE6/embed?start=false&loop=false&delayms=3000" 
                                frameBorder="0" 
                                width="900" 
                                height="718" 
                                allowFullScreen={true}
                                className='w-full h-full rounded-xl'

                            ></iframe>
                        </div>
                        
                    </div>
                    <div className=''>
                        <a 
                            href="https://docs.google.com/presentation/d/e/2PACX-1vQv-t4hcWs1MMqjEWi5xCCGIP8twnOCvLn56bLQd5swSitFaZiv_YAKjdItUi5zwMpRMDZqg7pptFCF/pub?start=false&loop=false&delayms=3000" 
                            target='_blank'
                            className={cn(buttonVariants({ variant: 'default' }), 'bg-cta border-[#F9EC31] border-[3px] text-[#1F1D40] font-figtree min-h-12 !font-bold shadow-cta px-6 py-2.5 gap-2 !leading-7 text-base lg:text-xl hover:scale-[1.02] !rounded-[66px]')}
                        >
                            Behold our Litepaper
                        </a>
                    </div>
                </motion.div>
            </div>
        </section>
    );
};