import { Button } from '@/components/ui/button';
import logo from '@/assets/images/logo.png';
import logoDark from '@/assets/images/logo-dark.svg';
import logoIcon from '@/assets/images/logo-icon.svg';
import logoIconDark from '@/assets/images/logo-icon-dark.svg';
import linkIcon from '@/assets/images/link-icon.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSections } from '@/components/section-provider';
import { Menu, X } from 'lucide-react';
import { motion, useCycle } from 'framer-motion';
import { useDimensions } from '@/hooks/use-dimensions';
import { Navigation } from '@/components/navigation/menu';
import { homeSections } from '@/registry/home-sections';
import { useSite } from '@/site-provider';
import { SocialsModal } from '@/components/modals/social-modal';

const sidebar = {
    open: (height = 1000) => ({
        zIndex: 50,
        opacity: 1,
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2,
            duration: 0.5,
            damping: 40
        }
    }),
    closed: {
        zIndex: -10,
        opacity: 0,
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40
        }
    }
};

export const Header = () => {

    const { scrollTo, setShowFooter } = useSections();
    const { Cta } = useSite();

    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);

    const [openSocials, setOpenSocials] = useState(false);

    useEffect(() => {
        const animateInterval = setInterval(() => {
            document.getElementById("cta")?.classList.add("bg-cta-active")
            setTimeout(() => {
                document.getElementById("cta")?.classList.remove("bg-cta-active")
            }, 600)
        }, 7000);

        return () => clearInterval(animateInterval);
    }, [])

    const sectionPress = useCallback((x: number) => {
        toggleOpen(1);
        document.getElementById(homeSections[x].id)?.scrollIntoView();
        setShowFooter(false);
    }, [isOpen, toggleOpen]);

    return (
        <>
        <motion.nav
            initial={false}
            animate={isOpen ? "open" : "closed"}
            custom={height}
            ref={containerRef}
            className='relative'
        >
            <motion.div
                className="fixed left-0 top-0 right-0 bottom-0 w-full h-full bg-[#1F1D40] bg-opacity-[0.98] flex flex-col items-center justify-center px-[40px] lg:px-32" 
                variants={sidebar} 
                transition={{ duration: 1 }}
                onClick={() => toggleOpen()}
            >
                <Navigation sectionPress={sectionPress} />
                {/* <SocialIcons className="pt-10 pl-10" iconClassName='border-[#423F78] bg-[#1F1D40] hover:bg-[#1F1D40]/90' /> */}
            </motion.div>
            <motion.button
                type="button"
                className='fixed bg-white top-4 right-4 md:top-20 md:right-20 z-50 rounded-full flex justify-center items-center w-10 h-10 md:w-[60px] md:h-[60px]'
                initial={false}
                animate={isOpen ? {
                    opacity: 1
                } : {
                    opacity: 0
                }}
                transition={{ duration: 1 }}
                onClick={() => toggleOpen()}
            >
                <X color='#1F1D40' className='w-6 h-6 md:w-10 md:h-10' />
            </motion.button>
        </motion.nav>
        <header className="fixed top-0 flex justify-between items-center gap-6 px-5 pt-2 md:pt-6 md:pr-16 w-full lg:pl-40 z-[12]">      
            <div className='flex items-center gap-x-2.5 lg:gap-x-8'>
                <button
                    className='flex flex-col'
                    onClick={() => toggleOpen()}
                >
                    <Menu className='text-foreground h-8 w-8' />
                </button>
                <Button
                    onClick={() => scrollTo(0, 0)}
                    className="hidden md:flex items-center"
                    variant="ghost"
                >
                    <img
                        src={logo}
                        className="max-w-[220px] h-[34px] logo !pointer-events-none"
                    />
                    <img
                        src={logoDark}
                        className="max-w-[220px] h-[34px] dark-logo"
                    />
                    <span className="sr-only">Lizard Labs</span>
                </Button>
                <Button
                    onClick={() => scrollTo(0, 0)}
                    className="flex md:hidden items-center"
                    variant="ghost"
                >
                    <img
                        src={logoIcon}
                        className="min-w-6 w-6 h-6 logo !pointer-events-none"
                    />
                    <img
                        src={logoIconDark}
                        className="min-w-6 w-6 h-6 dark-logo"
                    />
                    <span className="sr-only">Lizard Labs</span>
                </Button>
            </div>
            <div className='flex items-center gap-x-4 lg:gap-x-[36px]'>
                <Button 
                    onClick={() => setOpenSocials(!openSocials)}
                    className='foreground-border rounded-[26px] w-10 h-10 lg:w-auto lg:px-4 lg:py-2 bg-transparent font-nekstsemibold text-base leading-5 hover:bg-transparent hover:scale-[1.02] transition-all duration-200 text-foreground'
                >
                    <span className='hidden lg:flex'>Socials</span>
                    <img src={linkIcon} className='!h-5 !w-5 text-foreground flex lg:hidden' />
                </Button>
                {Cta}
            </div>
            
        </header>
        <SocialsModal 
            open={openSocials}
            onOpenChange={setOpenSocials}
        />
        </>
    );
};